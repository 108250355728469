import { HttpClient, HttpHeaders, HttpParams, JsonpClientBackend } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, catchError, Observable, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

export interface Device{
  id:number;
  serial: string;
  number?: number | null;
  company: string;
  user: string;
  color: string
}

export interface DeviceColors{
  id:number;
  colorCode: string;
  deviceId: number;
}

@Injectable({
  providedIn: 'root'
})
export class DeviceService  {

  private API_ENDPOINT: string;

  constructor(private http: HttpClient) {
    this.API_ENDPOINT = `${environment.apiURL}device/`;

  }

  public getAll()
  {
    return this.http.get<Array<Device>>(this.API_ENDPOINT + 'getAll',httpOptions);
  }

  public getAvailableColors(device:Device = null)
  {
if(device == null)
{
  return this.http.get<Array<DeviceColors>>(this.API_ENDPOINT + 'getAvailableColors',{...httpOptions});
}
else
{
  let queryParams = new HttpParams();
  queryParams = queryParams.append("DeviceID",device.id);

  return this.http.get<Array<DeviceColors>>(this.API_ENDPOINT + 'getAvailableColors',{...httpOptions,params:queryParams});
}

  }

  public add(device:Device)
  {
    return this.http.post<Device>(this.API_ENDPOINT + 'add',device,httpOptions);
  }

  public update(device:Device)
  {
    return this.http.post<Device>(this.API_ENDPOINT + 'update',device,httpOptions);
  }

  public delete(device:Device)
  {
    return this.http.post<Device>(this.API_ENDPOINT + 'delete',device,httpOptions);
  }


  handelError(errorRes)
  {
   let errorMessage = 'An unknown error occurred!'

   if(!errorRes.error||!errorRes.error.result)
   {
     return throwError(() => { return new Error(errorMessage)});
   }

   switch (errorRes.error.result) {
     case 'INVALID_CREDENTIALS':
       errorMessage = "Invalid Login Credentials";
       break;
     case 'EMAIL_EXISTS':
       errorMessage = "An Account Already Exists With That Email";
       break;

     default:
       break;
   }

   return throwError(() => { return new Error(errorMessage)});

  }
}


