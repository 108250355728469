import { Component, HostBinding, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { CustomValidators } from 'src/app/_helpers/customValidators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  error: string = null;
  loginForm: UntypedFormGroup;

  @HostBinding('class') class = 'login';

  constructor(private authService: AuthService, private router:Router, private tokenStorageService: TokenStorageService) {}

  ngOnInit(): void {
    this.loginForm = new UntypedFormGroup({
      email: new UntypedFormControl(null, [CustomValidators.required,CustomValidators.email]),
      password: new UntypedFormControl(null, CustomValidators.required),
    });

    this.loginForm.valueChanges.subscribe((changes) => {
      this.error = null;
    });
  }

  onSubmit(): void {

    let credentials = {
      email: this.loginForm.get("email").value,
      password:  this.loginForm.get("password").value
    }

    this.authService.login(credentials).subscribe(
      {
        next: (data) => {
          this.tokenStorageService.saveToken(data.accessToken);
          this.tokenStorageService.saveUser(data.user);
          this.router.navigate(["map"]);
        },
        error: (error) => {
          console.log(error.message);
          this.error = error.message;
        }
      }
    );

  }

  get email() { return this.loginForm.get('email'); }

  get password() { return this.loginForm.get('password'); }

}
