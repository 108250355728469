import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Device, DeviceService } from 'src/app/services/device.service';
import { UserDTO, UserService } from 'src/app/services/user.service';
import { AddDeviceDialogComponent } from '../../shared/dialogs/add-device-dialog/add-device-dialog.component';
import { AddUserDialogComponent } from '../../shared/dialogs/add-user-dialog/add-user-dialog.component';
import { DeleteUserComponent } from '../../shared/dialogs/user/delete-user/delete-user.component';
import { EditUserComponent } from '../../shared/dialogs/user/edit-user/edit-user.component';
import { ResetPasswordAdminComponent } from '../../shared/dialogs/user/reset-password-admin/reset-password-admin.component';
import { ErrorComponent } from '../../shared/snackbar/error/error.component';
import { SuccessComponent } from '../../shared/snackbar/success/success.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  displayedColumns: string[] = ['firstName', 'surname','email','isEmailConfirmed','isEnabled','company','lastLoginDate','roles','actions'];

  UserID: any;
FirstName: any;
Surname: any;
Email: any;
IsEmailConfirmed: any;
IsEnabled: any;
Company: any;
LastLoginDate: any;
Roles : any;

  data: Array<Device>;
  dataSource: any;

  @HostBinding('class') class = 'main-pane';
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private dialog:MatDialog, private userService:UserService,private snackBar: MatSnackBar) {
    this.load();
   }

  ngOnInit(): void {


  }

  load()
  {
    this.userService.getAll().subscribe(users => {
      this.dataSource = new MatTableDataSource<UserDTO>(users);
    })
  }

  ngAfterViewInit() {
   // this.dataSource.paginator = this.paginator;
  }

  addUser()
  {
    const dialogRef = this.dialog.open(AddUserDialogComponent,{hasBackdrop:true,width:'20%'});

    dialogRef.afterClosed().subscribe( async result => {
     if(result === null || result === undefined || result === false)
     {
      return;
     }
      console.table(result);

      this.userService.add(result).subscribe({
        next: (value) =>  {
          this.showSuccess("User added!")
          this.load();
        },
        error: (err) => {
          this.showError(err.message)
        },
      });

    });
  }

  showSuccess(message:string): void {
    this.snackBar.openFromComponent(SuccessComponent, {
      duration: 20 * 1000,
      data: message,
      panelClass: ['success-snackbar']
    });
  }

  showError(message:string):void{
    this.snackBar.openFromComponent(ErrorComponent, {
      duration: 20 * 1000,
      data: message,
      panelClass: ['error-snackbar']
    });
  }

  resetPassword(user:UserDTO)
  {
    const dialogRef = this.dialog.open(ResetPasswordAdminComponent,{hasBackdrop:true,width:'20%'});

    dialogRef.afterClosed().subscribe( async result => {
      if(result === null || result === undefined || result === false)
      {
       return;
      }

      this.userService.adminPasswordReset({email:user.email,password:result}).subscribe({
        next: (value) =>  {
          this.showSuccess("Password Updated")
          this.load();
        },
        error: (err) => {
          this.showError(err.message)
        },
      });


    });
  }

  edit(user:UserDTO)
  {
    const dialogRef = this.dialog.open(EditUserComponent,{hasBackdrop:true,width:'20%',data:user});

    dialogRef.afterClosed().subscribe( async result => {
      if(result === null || result === undefined || result === false)
      {
       return;
      }

      this.userService.update(result).subscribe({
        next: (value) =>  {
          this.showSuccess("User updated!")
          this.load();
        },
        error: (err) => {
          this.showError(err.message)
        },
      });


    });
  }

  delete(user:UserDTO)
  {
    const dialogRef = this.dialog.open(DeleteUserComponent,{hasBackdrop:true,width:'20%',data:user});

    dialogRef.afterClosed().subscribe( async result => {
      if(result === null || result === undefined || result === false)
      {
       return;
      }

      if(result === true)
      {
        this.userService.delete(user).subscribe(response => {
          this.showSuccess("User deleted")
          this.load();
        });
      }


    });

  }
}
