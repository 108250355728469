export class User {
  constructor(public id:number, public email:string, public userFullName : string, public token: string, public refreshToken: string, public isAdmin: boolean = false, public companyID:number,public roles: Array<string> = []){

  }

  checkPermissions():boolean{
    return (this.roles.includes('Administrator') || this.roles.includes('Forest Owner'));
  }
  checkIsAdmin():boolean{
    return (this.roles.includes('Administrator'));
  }
}
