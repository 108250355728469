import { AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Feature, Overlay, PluggableMap } from 'ol';
import { MultiPolygon, Point, Polygon } from 'ol/geom';
import {getCenter} from 'ol/extent';
import { CompanyService } from 'src/app/services/company.service';
import { DeviceService } from 'src/app/services/device.service';
import { combineLatest } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { RestoreConflictedTreePointDialogComponent } from 'src/app/components/shared/dialogs/restore-conflicted-tree-point-dialog/restore-conflicted-tree-point-dialog.component';
import { TreeService } from 'src/app/services/tree.service';
import { outputAst } from '@angular/compiler';



@Component({
  selector: 'app-attribute-overlay',
  templateUrl: './attribute-overlay.component.html',
  styleUrls: ['./attribute-overlay.component.css'],
   host: {'class': 'ol-popup'}
})
export class AttributeOverlayComponent implements OnInit,OnDestroy,AfterViewInit {

  @Input() selectedFeature: Feature<any>;
  @Input() map: PluggableMap
  @Input() layerTitle: string
  @Input() attributeAliasMap: Map<any,any>;
  @Output() loadTrees = new EventEmitter()

  title: string = '';
  attributes = [];
  companies = [];
  devices = []

  isConflictedTreePoint: boolean = false;

  overlay: Overlay;

  constructor(private elRef:ElementRef, private companyService: CompanyService, private deviceService: DeviceService, private dialog:MatDialog, private treeService: TreeService) {



    combineLatest([deviceService.getAll(),companyService.companies]).subscribe(
      ([devices, companies]) => {
        this.devices = devices.map(object => {
          return [object.id, object.serial];
        });

        this.companies = companies.map(object => {
          return [object.id, object.name];
        });

        this.setupAttributesArray();

      }
    )

  }

  ngOnInit(): void {

    this.overlay = new Overlay({
      element: this.elRef.nativeElement,
      autoPan: {
        animation: {
          duration: 250,
        },
      },
    });


    this.isConflictedTreePoint = this.layerTitle == 'Conflicted Tree Points' ? true : false;

  }

  ngAfterViewInit(): void {

    let geom = this.selectedFeature.getGeometry();

    if( geom instanceof Point)
    {
      this.title = this.layerTitle.endsWith('s') ? this.layerTitle.slice(0,-1) : this.layerTitle;
    }
    else if(geom instanceof MultiPolygon || geom instanceof Polygon)
    {
      this.title = this.selectedFeature.getProperties()['CoupeName'];
    }

    let extent = geom.getExtent();
    this.overlay.setPosition([(extent[0] + extent[2] )/2, (extent[1])]);
    this.overlay.setOffset([-188,20])

    this.map.addOverlay(this.overlay);

  }

  ngOnDestroy(): void {
      this.map.removeOverlay(this.overlay);
  }

  setupAttributesArray()
  {

    let props = this.selectedFeature.getProperties();

    for (const property in props) {
      let name = property;
      let value = props[property] === null ? '' : props[property];

      if(name.toLowerCase() == 'netting' || name.toLowerCase() == 'fertiliser')
      {
        value = ((value == '1' || value == '0') && !name.toLowerCase().endsWith('id')) ? Boolean(value) ? 'Yes' : 'No' : value;
      }

      if(name.toLowerCase() =='companyid')
      {
        value = this.companies[value][1];
      }

      if(name.toLowerCase() =='deviceid')
      {
        value = this.devices[value][1];
      }

      if(name.toLowerCase() == 'utc')
      {
        value = new Date(value).toLocaleString()
      }

      if (this.attributeAliasMap) {
        name =
          this.attributeAliasMap.get(property) == null ||
          this.attributeAliasMap.get(property) == undefined
            ? property
            : this.attributeAliasMap.get(property);
      }


      name = name.toLowerCase().endsWith('id') && !name.toLowerCase().startsWith('id') ? name.replace('ID','').trim() : name;
      name = name.replace(/([A-Z])/g, ' $1').trim();

      if (property === 'geometry') continue;

      let row = {
        attribute: name,
        value: value
      }

      this.attributes.push(row)
    }

  }

  close(event)
  {
    this.overlay.setPosition(undefined);
    event.target.blur();
    this.overlay.dispose();
  }

  confirmRestore()
  {
    this.dialog.open(RestoreConflictedTreePointDialogComponent)
    .afterClosed()
    .subscribe(result =>{

      if(!result)
      return;

      this.treeService.restoreConflictedTree(this.selectedFeature.getProperties()['Id']).subscribe(
        response => {
          this.loadTrees.emit();
          this.overlay.setPosition(undefined);
          this.overlay.dispose();
        }
      );
    });
  }

}
