<h2 mat-dialog-title>Reset Password</h2>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="adminNewPasswordForm" (ngSubmit)="onSubmit()">
    <section class="grid">
      <mat-form-field appearance="outline">
        <mat-label>New Password</mat-label>
        <input
          formControlName="password"
          matInput
          type="password"
        />
        <mat-error *ngIf="adminNewPasswordForm.get('password').errors"
          >This field is required!</mat-error
        >
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Repeat Password</mat-label>
        <input
        formControlName="rptPassword"
          matInput

          type="password"
        />
        <mat-error *ngIf="adminNewPasswordForm.get('rptPassword').errors"
          >This field is required!</mat-error
        >
      </mat-form-field>

    </section>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <footer>
    <button
      type="button"
      class="button button--black-outline"
      [mat-dialog-close]="false"
    >
      Cancel
    </button>
    <button
      type="button"
      class="button button--blue"
      [disabled]="!adminNewPasswordForm.valid"
      (click)="onSubmit()"
      type="submit"
    >
      Update
    </button>
  </footer>
</mat-dialog-actions>
