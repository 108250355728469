import { HttpClient, HttpHeaders, JsonpClientBackend } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { HttpOptions } from '@capacitor/core';
import { BehaviorSubject, catchError, Observable, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';


const httpOptions= {
  headers: new HttpHeaders({ 'Content-Type': 'text/csv', 'Content-Disposition' : 'attachment;filename=myfilename.csv'}
  ),
  responseType: 'blob' as const
};

export interface TreeDTO{
  id:number;
  name:string;
}

@Injectable({
  providedIn: 'root'
})
export class TreeService  {

  private API_ENDPOINT: string;

  constructor(private http: HttpClient) {
    this.API_ENDPOINT = `${environment.apiURL}Tree/`;

  }

  public getAll(queryString: string = '')
  {
    return this.http.get<any>(this.API_ENDPOINT + 'getAll' + queryString,{headers: new HttpHeaders({ 'Content-Type': 'text/csv', 'Content-Disposition' : 'attachment;filename=myfilename.csv'}
    ), responseType: 'blob' as any});
  }

  public restoreConflictedTree(Id:Number)
  {
    return this.http.post<any>(this.API_ENDPOINT + 'restoreConflictedTree/' + Id,{});
  }


  handelError(errorRes)
  {
   let errorMessage = 'An unknown error occurred!'

   if(!errorRes.error||!errorRes.error.result)
   {
     return throwError(() => { return new Error(errorMessage)});
   }

   switch (errorRes.error.result) {
     case 'INVALID_CREDENTIALS':
       errorMessage = "Invalid Login Credentials";
       break;
     case 'EMAIL_EXISTS':
       errorMessage = "An Account Already Exists With That Email";
       break;

     default:
       break;
   }

   return throwError(() => { return new Error(errorMessage)});

  }
}
