import { Feature, PluggableMap } from 'ol';
import RenderFeature from 'ol/render/Feature';
import { Circle, Fill, RegularShape, Stroke, Style, Text } from 'ol/style';

let styleCache: Map<string, Style | Style[]> = new Map();

enum CompanyName {
  Forico = 1,
  FourSeasons = 2,
  EskMapping = 3,
}

let companyColors = {
  1: [231,51,42],
  2: [0,214,0],
  3: [255,140,0],
};

export function getCoupeStyle(
  feature: Feature<any> | RenderFeature,
  resolution,
  filter,
  map?: PluggableMap
) {
  let featProps = feature.getProperties();
  let styles: Array<Style> = [];
  let opacity = 1;
  let color: Array<number>;
  let stroke = [0, 0, 0, 1];
  let companyID = featProps.CompanyID;

  let styleKey = `companyCoupeStyle-${companyID}`;

  switch (companyID) {
    case CompanyName.Forico:
      color = companyColors[companyID];
      break;
    case CompanyName.FourSeasons:
      color = companyColors[companyID];
      break;
    case CompanyName.EskMapping:
      color = companyColors[companyID];
      break;
    default:
      break;
  }

  if (!styleCache.has(styleKey)) {
    let style = new Style({
      fill: new Fill({
        color: `rgba(${color.join(',')},0.05)`
      }),
      stroke: new Stroke({
        width: 1,
        color:`rgb(${color.join(',')})`,
      }),
    });

    styleCache.set(styleKey, style);
  }

  let cachedStyle = styleCache.get(styleKey);

  if (cachedStyle instanceof Array) {
    styles.push(...cachedStyle);
  } else {
    styles.push(cachedStyle);
  }

  let labelStyle = new Style({
    text: new Text({
      text: featProps.CoupeName,
      font: '400 18px "Arial"',
      fill: new Fill({
        color: [255, 255, 255, 1.0],
      }),
      // rotation : rotation,
      textBaseline: 'bottom',
      stroke: new Stroke({ color: [0, 0, 0, 1.0], width: 2 }),
    }),

    zIndex: 100,
  });

  styles.push(labelStyle);

  return styles;
}
