import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Company, CompanyService, CompanyTypes } from 'src/app/services/company.service';
import { CustomValidators } from 'src/app/_helpers/customValidators';
import { AddDeviceDialogComponent } from '../add-device-dialog/add-device-dialog.component';

@Component({
  selector: 'app-add-company-dialog',
  templateUrl: './add-company-dialog.component.html',
  styleUrls: ['./add-company-dialog.component.css']
})
export class AddCompanyDialogComponent implements OnInit {


  addCompanyForm: UntypedFormGroup;

  private companySub$: Subscription;

  public companyTypes: Array<CompanyTypes>;

  constructor(private companyService:CompanyService,public dialogRef: MatDialogRef<AddDeviceDialogComponent>) {

    this.companyService.getAllCompanyTypes().subscribe({
      next: (value) => {this.companyTypes = value}
    })

    this.addCompanyForm = new UntypedFormGroup({
      'name': new UntypedFormControl(null, CustomValidators.required),
      'type': new UntypedFormControl(null, CustomValidators.required)});

   }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
      this.companySub$?.unsubscribe();
  }

  onSubmit(){
    let formValue = this.addCompanyForm.value;
    let userSubmission : Company = {
    name:formValue.name,
    type:formValue.type
    }

    this.dialogRef.close(userSubmission);
  }
}
