import { AfterViewInit, Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Device, DeviceService } from 'src/app/services/device.service';
import { AddDeviceDialogComponent } from '../../shared/dialogs/add-device-dialog/add-device-dialog.component';
import { DeleteDeviceComponent } from '../../shared/dialogs/device/delete-device/delete-device.component';
import { EditDeviceComponent } from '../../shared/dialogs/device/edit-device/edit-device.component';
import { ErrorComponent } from '../../shared/snackbar/error/error.component';
import { SuccessComponent } from '../../shared/snackbar/success/success.component';



@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.css']
})
export class DevicesComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['serial','number', 'company', 'user','color','actions'];

  data: Array<Device>;
  dataSource: any;

  @HostBinding('class') class = 'main-pane';
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private dialog:MatDialog, private deviceService:DeviceService,private snackBar: MatSnackBar) {
    this.loadDevices();
   }

  ngOnInit(): void {


  }

  loadDevices()
  {
    this.deviceService.getAll().subscribe(devices => {
      this.dataSource = new MatTableDataSource<Device>(devices);
    })
  }

  ngAfterViewInit() {
   // this.dataSource.paginator = this.paginator;
  }

  addDevice()
  {
    const dialogRef = this.dialog.open(AddDeviceDialogComponent,{hasBackdrop:true,width:'20%'});

    dialogRef.afterClosed().subscribe( async result => {
     if(result === null || result === undefined)
     {
      return;
     }

     this.deviceService.add(result).subscribe(response => {
      this.snackBar.openFromComponent(SuccessComponent, {
        duration: 20 * 1000,
        data: 'Device added',
        panelClass: ['success-snackbar']
      });
      this.loadDevices();
     })
    });
  }

  edit(device:Device)
  {

    const dialogRef = this.dialog.open(EditDeviceComponent,{hasBackdrop:true,width:'20%',data:device});

    dialogRef.afterClosed().subscribe( async result => {
      if(result === null || result === undefined || result == false)
      {
       return;
      }
console.log(result);
      this.deviceService.update(result).subscribe({
        next: (value) =>  {
          this.showSuccess("Device updated!")
          this.loadDevices();
        },
        error: (err) => {
          this.showError(err.message)
        },
      });

  });

  }

  delete(device:Device)
  {
    const dialogRef = this.dialog.open(DeleteDeviceComponent,{hasBackdrop:true,width:'20%',data:device});

    dialogRef.afterClosed().subscribe( async result => {
      if(result === null || result === undefined || result == false)
      {
       return;
      }

      this.deviceService.delete(device).subscribe({
        next: (value) =>  {
          this.showSuccess("Device Deleted")
          this.loadDevices();
        },
        error: (err) => {
          this.showError(err.message)
        },
      });

  });

  }

  showSuccess(message:string): void {
    this.snackBar.openFromComponent(SuccessComponent, {
      duration: 20 * 1000,
      data: message,
      panelClass: ['success-snackbar']
    });
  }

  showError(message:string):void{
    this.snackBar.openFromComponent(ErrorComponent, {
      duration: 20 * 1000,
      data: message,
      panelClass: ['error-snackbar']
    });
  }

}
