import { HttpClient, HttpHeaders, JsonpClientBackend } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { rhumbDistance } from '@turf/turf';
import { BehaviorSubject, catchError, Observable, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

export interface Company{
id?: number;
name: string;
type: string;
}

export interface CompanyTypes{
  id: number,
  name: string
}

@Injectable({
  providedIn: 'root'
})
export class CompanyService  {

  private API_ENDPOINT: string;

  companies: BehaviorSubject<Array<Company>> = new BehaviorSubject([]);

  constructor(private http: HttpClient) {
    this.API_ENDPOINT = `${environment.apiURL}company/`;

    this.getAll().subscribe(value => this.companies.next(value));

  }

  public getAll()
  {
    return this.http.get<Array<Company>>(this.API_ENDPOINT + 'getAll',httpOptions);
  }

  public add(company:Company)
  {
    return this.http.post<Company>(this.API_ENDPOINT + 'add',company,httpOptions);
  }

  public update(company:Company)
  {
    return this.http.post<Company>(this.API_ENDPOINT + 'update',company,httpOptions);
  }

  public delete(company:Company)
  {
    return this.http.post<Company>(this.API_ENDPOINT + 'delete',company,httpOptions);
  }



  public getAllCompanyTypes()
  {
    return this.http.get<Array<CompanyTypes>>(this.API_ENDPOINT + 'getAllCompanyTypes',httpOptions);
  }

  handelError(errorRes)
  {
   let errorMessage = 'An unknown error occurred!'

   if(!errorRes.error||!errorRes.error.result)
   {
     return throwError(() => { return new Error(errorMessage)});
   }

   switch (errorRes.error.result) {
     case 'INVALID_CREDENTIALS':
       errorMessage = "Invalid Login Credentials";
       break;
     case 'EMAIL_EXISTS':
       errorMessage = "An Account Already Exists With That Email";
       break;

     default:
       break;
   }

   return throwError(() => { return new Error(errorMessage)});

  }
}


