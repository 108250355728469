export var base_map_defs = [{
  "active": true,
  "attribution_text": " \u00a9 State of Tasmania",
  "attribution_url": "https://services.thelist.tas.gov.au/arcgis/rest/services/Basemaps/Hillshade/MapServer?f=json",
  "display_name": "Hillshade colour (theLIST)",
  "epsg": 3857,
  "extent_wgs84_ulx":143.82,
  "extent_wgs84_uly":-43.66,
  "extent_wgs84_lrx":148.48,
  "extent_wgs84_ury":-39.58,
  "id":'72c16242-abe3-49a0-add0-52280603dd82',
  "matrix_set": "",
  "max_zoom": 18,
  "min_zoom": 0,
  "name": "WMTS_LIST_HILLSHADE",
  "ol_type": "XYZ",
  "options": "",
  "order": 5,
  "url": "https://services.thelist.tas.gov.au/arcgis/rest/services/Basemaps/Hillshade/MapServer/tile/{z}/{y}/{x}",
  "visible": false,
  "version_id":"ae945263-6640-4eed-b675-266c280bd4db"
},{
  "active": true,
  "attribution_text": " \u00a9 State of Tasmania",
  "attribution_url": "https://services.thelist.tas.gov.au/arcgis/rest/services/Basemaps/Hillshade/MapServer?f=json",
  "display_name": "Hillshade (theLIST)",
  "epsg": 3857,
  "extent_wgs84_ulx":143.82,
  "extent_wgs84_uly":-43.66,
  "extent_wgs84_lrx":148.48,
  "extent_wgs84_ury":-39.58,
  "id": 'd84030c3-6b5b-4f08-8fad-ac21a144e85a',
  "matrix_set": "",
  "max_zoom": 18,
  "min_zoom": 0,
  "name": "WMTS_LIST_HILLSHADE",
  "ol_type": "XYZ",
  "options": "",
  "order": 4,
  "url": "https://services.thelist.tas.gov.au/arcgis/rest/services/Basemaps/HillshadeGrey/MapServer/tile/{z}/{y}/{x}",
  "visible": false,
  "version_id":"6dc25269-a412-420c-93b7-0e9173ad5b3a"
},{
  "active": true,
  "attribution_text": " \u00a9 State of Tasmania",
  "attribution_url": "https://services.thelist.tas.gov.au/arcgis/rest/services/Basemaps/Topographic/MapServer?f=json",
  "display_name": "Topographic (theLIST)",
  "epsg": 3857,
  "extent_wgs84_ulx":143.82,
  "extent_wgs84_uly":-43.66,
  "extent_wgs84_lrx":148.48,
  "extent_wgs84_ury":-39.58,
  "id": '9c7f97db-7a3b-4ce0-afaa-914f9327cfe7',
  "matrix_set": "",
  "max_zoom": 18,
  "min_zoom": 0,
  "name": "WMTS_LIST_TOPO",
  "ol_type": "XYZ",
  "options": "",
  "order": 3,
  "url": "https://services.thelist.tas.gov.au/arcgis/rest/services/Basemaps/Topographic/MapServer/tile/{z}/{y}/{x}",
  "visible": true,
  "version_id":"def39ec4-23bd-4fd2-9334-3fd2c1f28dc9"
},{
  "active": true,
  "attribution_text": " \u00a9 State of Tasmania",
  "attribution_url": "https://services.thelist.tas.gov.au/arcgis/rest/services/Basemaps/Orthophoto/MapServer?f=json",
  "display_name": "Aerial Image (theLIST)",
  "epsg": 3857,
  "extent_wgs84_ulx":143.82,
  "extent_wgs84_uly":-43.66,
  "extent_wgs84_lrx":148.48,
  "extent_wgs84_ury":-39.58,
  "id": 'aa68ee8a-6a87-4535-90e3-a66fd1201710',
  "matrix_set": "",
  "max_zoom": 19,
  "min_zoom": 0,
  "name": "WMTS_LIST_ORTHO",
  "ol_type": "XYZ",
  "options": "",
  "order": 2,
  "url": "https://services.thelist.tas.gov.au/arcgis/rest/services/Basemaps/Orthophoto/MapServer/tile/{z}/{y}/{x}",
  "visible": false,
  "version_id":"73ac03d7-08d8-4b8a-a344-a02450fa3cd7"
},
{
  "active": true,
  "attribution_text": "Basemap \u00a9 Esri, Maxar, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community",
  "attribution_url": "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer?f=json",
  "display_name": "Esri World Image",
  "epsg": 3857,
  "id": 3,
  "matrix_set": "",
  "max_zoom": 23,
  "min_zoom": 0,
  "name": "WMTS_ARCGIS_WORLD",
  "ol_type": "XYZ",
  "options": "",
  "order": 3,
  "url": "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
  "visible": false
}
]


export var defaultLayers = [{
  "active": true,
  "attribution_text": " \u00a9 State of Tasmania",
  "attribution_url": "https://services.thelist.tas.gov.au/arcgis/rest/services/Basemaps/Hillshade/MapServer?f=json",
  "display_name": "EnterpriseSuitabilityMapping2018",
  "epsg": 3857,
  "extent_wgs84_ulx":143.82,
  "extent_wgs84_uly":-43.66,
  "extent_wgs84_lrx":148.48,
  "extent_wgs84_ury":-39.58,
  "id":'72c16242-abe3-49a0-add0-52280603dd82',
  "matrix_set": "",
  "max_zoom": 18,
  "min_zoom": 0,
  "legend_layer_ids": new Set([5]),
  "name": "ESM_2018_Cherry",
  "ol_type": "ArcGISREST",
  "params": {
    "TRANSPARENT":"true",
    "OPACITY":1,
    "LAYERS":"show:5",
    "f":"image",
    "FORMAT":"png"
  },
  "url": "https://services.thelist.tas.gov.au/arcgis/rest/services/Public/EnterpriseSuitabilityMapping2018/MapServer",
  "visible": false,
  "version_id":"ae945263-6640-4eed-b675-266c280bd4db"
}]
