import { Component, HostBinding, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {


  @HostBinding('class') class = 'main-container';

  user:User;


  userSub$: Subscription = new Subscription();

  constructor(private router:Router, private authService: AuthService) {

    this.userSub$ = authService.user.subscribe(user => {
      this.user = user;
    })
   }

  ngOnInit(): void {
    this.router.navigate([ '',{ outlets: { aux: ['dashboard'] } }]);
  }


}
