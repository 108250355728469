import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Company, CompanyService } from 'src/app/services/company.service';
import { RoleDTO, RoleService } from 'src/app/services/role.service';
import { UserDTO } from 'src/app/services/user.service';
import { CustomValidators } from 'src/app/_helpers/customValidators';
import { AddUserDialogComponent } from '../../add-user-dialog/add-user-dialog.component';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {

  editUserForm: UntypedFormGroup;

  private companySub$: Subscription;

  public companies: Array<Company>;

  public roles: Array<RoleDTO>;

  constructor(private companyService:CompanyService, private roleService:RoleService,public dialogRef: MatDialogRef<EditUserComponent>,@Inject(MAT_DIALOG_DATA) private user: UserDTO) {

    this.companySub$ = companyService.companies.subscribe(companies => {
      this.companies = companies
    });

 roleService.getAll().subscribe(roles => {
  this.roles =roles;
});

    this.editUserForm = new UntypedFormGroup({
      'firstName': new UntypedFormControl(null, CustomValidators.required),
      'surname': new UntypedFormControl(null, CustomValidators.required),
      'email': new UntypedFormControl({value: '', disabled: true}, [CustomValidators.required]),
      'isEnabled': new UntypedFormControl(null, [CustomValidators.required]),
      'isEmailConfirmed': new UntypedFormControl(null, [CustomValidators.required]),
      'roles': new UntypedFormControl(null),
      'company': new UntypedFormControl(null, CustomValidators.required)});



    this.editUserForm.patchValue(user);

   }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
      this.companySub$?.unsubscribe();
  }

  onSubmit(){

    Object.assign(this.user,this.editUserForm.value);

    this.dialogRef.close(this.user);
  }

}
