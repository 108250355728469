<h2 mat-dialog-title>Edit Company</h2>
<mat-dialog-content class="mat-typography">

  <form
    [formGroup]="editCompanyForm"
    (ngSubmit)="onSubmit()"
  >
  <section class="grid">
    <mat-form-field appearance="outline">
      <mat-label>Name</mat-label>
      <input formControlName="name" matInput placeholder="ESK Mapping">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Type</mat-label>
      <mat-select formControlName="type">
        <option [value]="null" disabled>Choose company type</option>
        <mat-option *ngFor="let type of companyTypes" [value]="type.name">
          {{type.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </section>

  </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <footer>
    <button type="button" class="button button--black-outline" [mat-dialog-close]="false">
      Cancel
    </button>
    <button
      type="button"
      class="button button--blue"
      [disabled]="!editCompanyForm.dirty"
      (click)="onSubmit()"
      type="submit"
    >
      Save
    </button>
  </footer>
</mat-dialog-actions>
