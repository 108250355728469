import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Company, CompanyService } from 'src/app/services/company.service';
import { Device, DeviceColors, DeviceService } from 'src/app/services/device.service';
import { UserDTO, UserService } from 'src/app/services/user.service';
import { CustomValidators } from 'src/app/_helpers/customValidators';
import { AddDeviceDialogComponent } from '../../add-device-dialog/add-device-dialog.component';

@Component({
  selector: 'app-edit-device',
  templateUrl: './edit-device.component.html',
  styleUrls: ['./edit-device.component.css']
})
export class EditDeviceComponent implements OnInit {


  editDeviceForm: UntypedFormGroup;

  private companySub$: Subscription;

  private userSub$: Subscription;

  public companies: Array<Company>;

  public users: Array<UserDTO>;

  public colors: Array<DeviceColors> = [];

  constructor(private companyService:CompanyService,private userService: UserService,private deviceService:DeviceService, public dialogRef: MatDialogRef<AddDeviceDialogComponent>
    ,@Inject(MAT_DIALOG_DATA) private device: Device) {

    this.companySub$ = companyService.companies.subscribe(companies => {
      this.companies = companies
    });
    userService.getAll().subscribe(_users => {
      this.users = _users
    });

    deviceService.getAvailableColors(device).subscribe(colors => {
      this.colors = colors;
    })

    this.editDeviceForm = new UntypedFormGroup(
      {
      'serial': new UntypedFormControl(null, CustomValidators.required),
      'number': new UntypedFormControl(null),
      'company': new UntypedFormControl(null, CustomValidators.required),
      'user': new UntypedFormControl(null, CustomValidators.required),
      'color': new UntypedFormControl(null, CustomValidators.required)
    },
    );

      this.editDeviceForm.patchValue(device);

   }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
      this.companySub$?.unsubscribe();
  }

  onSubmit()
  {
    Object.assign(this.device,this.editDeviceForm.value);

    this.dialogRef.close(this.device);
  }

}
