import { HttpClient, HttpHeaders, JsonpClientBackend } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, catchError, Observable, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Coupe } from '../components/main/coupes/coupes.component';


const TOKEN_KEY = 'auth-token';
const REFRESH_KEY = 'auth-refresh';
const USER_KEY = 'auth-user';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

export class Alert{
title: string;
link: string;
pubDate: string;
}

@Injectable({
  providedIn: 'root'
})
export class CoupeService  {

  private API_ENDPOINT: string;

  constructor(private http: HttpClient) {
    this.API_ENDPOINT = `${environment.apiURL}coupe/`;
  }

  public getAll():Observable<any>
  {
    return this.http.get<any>(this.API_ENDPOINT + 'getAll',httpOptions);
  }

  public add(content)
  {
    return this.http.post<any>(this.API_ENDPOINT + 'add',content).pipe(catchError(this.handelError));
  }

  public update(coupe:Coupe)
  {
    return this.http.post<any>(this.API_ENDPOINT + 'update',coupe).pipe(catchError(this.handelError));
  }

  public delete(coupe:Coupe)
  {
    return this.http.post<any>(this.API_ENDPOINT + 'delete',coupe).pipe(catchError(this.handelError));
  }


  handelError(errorRes)
  {
   let errorMessage = 'An unknown error occurred!'

   if(!errorRes.error||!errorRes.error.result)
   {
     return throwError(() => { return new Error(errorMessage)});
   }

   switch (errorRes.error.result) {
     case 'INVALID_CREDENTIALS':
       errorMessage = "Invalid Login Credentials";
       break;
     case 'EMAIL_EXISTS':
       errorMessage = "An Account Already Exists With That Email";
       break;

     default:
       break;
   }

   return throwError(() => { return new Error(errorMessage)});

  }
}


