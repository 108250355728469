
<h2 mat-dialog-title>Import Coupe</h2>
<mat-dialog-content class="mat-typography spatialSelector">
  <div #mapElementRef1 style="width: 400px; height:400px;"></div>
  <div #mapElementRef2></div>
  <div #mapElementRef3></div>
  <div #mapElementRef4></div>
  <div #mapElementRef5></div>
  <div #mapElementRef6></div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <footer>
  </footer>
</mat-dialog-actions>
