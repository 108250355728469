<h2 mat-dialog-title>Import Coupe</h2>
<mat-dialog-content class="mat-typography">

  <form
    [formGroup]="coupeImportForm"
    (ngSubmit)="onSubmit()"
  >
  <section class="main-section">
    <div #mapElementRef1 style="width: 100%; height:400px;" class="map-container"></div>
    <section class="grid form-container">
      <mat-form-field appearance="outline">
        <mat-label>Coupe Code</mat-label>
        <input autocomplete="new-password" formControlName="coupeCode" matInput>
        <mat-error *ngIf="coupeImportForm.get('coupeCode').errors?.['required']">This field is required!</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Coupe Name</mat-label>
        <input autocomplete="new-password" formControlName="coupeName" matInput>
        <mat-error *ngIf="coupeImportForm.get('coupeName').errors?.['required']">This field is required!</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Tree species</mat-label>
        <mat-select formControlName="treeSpecies">
          <mat-option value="E. nitens">E. nitens</mat-option>
          <mat-option value="P. radiata">P. radiata</mat-option>
        </mat-select>
        <mat-error *ngIf="coupeImportForm.get('treeSpecies').errors?.['required']">This field is required!</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Seedlot</mat-label>
        <input type="text" formControlName="seedlot" matInput>
        <mat-error *ngIf="coupeImportForm.get('seedlot').errors?.['required']">This field is required!</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Target Stems Per Hectare</mat-label>
        <input type="number" formControlName="targetStems" matInput>
        <mat-error *ngIf="coupeImportForm.get('targetStems').errors?.['required']">This field is required!</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Row spacing (m)</mat-label>
        <input  type="number"  formControlName="rowSpacing" matInput>
        <mat-error *ngIf="coupeImportForm.get('rowSpacing').errors?.['required']">This field is required!</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Inter-row spacing (m)</mat-label>
        <input  type="number"formControlName="interRowSpacing" matInput>
        <mat-error *ngIf="coupeImportForm.get('interRowSpacing').errors?.['required']">This field is required!</mat-error>
      </mat-form-field>

      <div>
        <label style="display: inline;" class="form-label">Netting
          <div>
            <label style="display: inline;">
              <input type="radio" value="1" formControlName="hasNetting">
                <span>Yes</span>
              </label>
              <label style="display: inline;">
              <input type="radio" value="0" formControlName="hasNetting">
                <span>No</span>
              </label>
          </div>
        </label>
        <label  style="display: inline;" class="form-label">Fertilizer

          <div>
            <label style="display: inline;">
              <input type="radio" value="1" formControlName="hasFertilizer">
                <span>Yes</span>
              </label>
              <label style="display: inline;">
              <input type="radio" value="0" formControlName="hasFertilizer">
                <span>No</span>
              </label>
          </div>

        </label>
      </div>

      <mat-form-field appearance="outline">
        <mat-label>Nursery</mat-label>
        <mat-select formControlName="nursery">
          <mat-option selected value="Forico Somerset">Forico Somerset</mat-option>
        </mat-select>
        <mat-error *ngIf="coupeImportForm.get('nursery').errors?.['required']">This field is required!</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Company</mat-label>
        <mat-select [disabled]="!user.checkIsAdmin()" formControlName="company">
          <option [value]="null" disabled>Choose company</option>
          <mat-option *ngFor="let company of companies" [value]="company.id">
            {{company.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </section>

    </section>


  </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <footer>
    <button type="button" class="button button--black-outline" [mat-dialog-close]="false">
      Cancel
    </button>
    <button
      class="button button--blue"
      [disabled]="!coupeImportForm.valid"
      type="submit"
      (click)="onSubmit()"
    >
      Save
    </button>
  </footer>
</mat-dialog-actions>
