import { HttpClient, HttpHeaders, JsonpClientBackend } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, catchError, Observable, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Device } from './device.service';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' })
};

export interface ImportResponse{
  top:number;
  left:number;
  bottom:number;
  right:number;
  totalImported:Number;
  totalRows:Number;
  devices:Array<Device>;
}

@Injectable({
  providedIn: 'root'
})
export class ImportService  {

  private API_ENDPOINT: string;

  constructor(private http: HttpClient) {
    this.API_ENDPOINT = `${environment.apiURL}UploadCSV`;

  }

  public import(content)
  {
    return this.http.post<ImportResponse>(this.API_ENDPOINT,content).pipe(catchError(this.handelError));
  }


  handelError(errorRes)
  {
   let errorMessage = 'An unknown error occurred!'

   if(!errorRes.error||!errorRes.error.result)
   {
     return throwError(() => { return new Error(errorMessage)});
   }

   if(errorRes.error.result.includes('DEVICE_NOT_IN_SYSTEM'))
   {
    return throwError(() => { return new Error(errorRes.error.result)});
   }

   switch (errorRes.error.result) {
     case 'DEVICE_NOT_IN_SYSTEM':
       errorMessage = "DEVICE_NOT_IN_SYSTEM";
       break;
     case 'INVALID_CSV':
       errorMessage = "The CSV provided is in an invalid format, please check it meets the 2021 or 2022 import specifications";
       break;

     default:
       break;
   }

   return throwError(() => { return new Error(errorMessage)});

  }
}
