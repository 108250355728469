import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Company, CompanyService } from 'src/app/services/company.service';
import { AddCompanyDialogComponent } from '../../shared/dialogs/add-company-dialog/add-company-dialog.component';
import { DeleteCompanyComponent } from '../../shared/dialogs/company/delete-company/delete-company.component';
import { EditCompanyComponent } from '../../shared/dialogs/company/edit-company/edit-company.component';
import { ErrorComponent } from '../../shared/snackbar/error/error.component';
import { SuccessComponent } from '../../shared/snackbar/success/success.component';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.css']
})
export class CompaniesComponent implements OnInit {

  displayedColumns: string[] = ['name','type','actions'];

  dataSource: any;

  @HostBinding('class') class = 'main-pane';
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private dialog:MatDialog, private companyService:CompanyService,private snackBar: MatSnackBar) {

    this.companyService.companies.subscribe(companies => {
      this.dataSource = new MatTableDataSource<Company>(companies);
    })
   }

  ngOnInit(): void {
  }


  addDevice()
  {
    const dialogRef = this.dialog.open(AddCompanyDialogComponent,{hasBackdrop:true,width:'20%'});

    dialogRef.afterClosed().subscribe( async result => {
     if(result === null || result === undefined)
     {
      return;
     }

     this.companyService.add(result).subscribe(result => {

      this.loadCompanies();
     });


    });
  }

  loadCompanies()
  {
    this.companyService.getAll();
  }

  edit(company:Company)
  {

    const dialogRef = this.dialog.open(EditCompanyComponent,{hasBackdrop:true,width:'20%',data:company});

    dialogRef.afterClosed().subscribe( async result => {
      if(result === null || result === undefined || result == false)
      {
       return;
      }

      this.companyService.update(result).subscribe({
        next: (value) =>  {
          this.showSuccess("Company updated!")
          this.loadCompanies();
        },
        error: (err) => {
          this.showError(err.message)
        },
      });

  });

  }

  delete(company:Company)
  {
    const dialogRef = this.dialog.open(DeleteCompanyComponent,{hasBackdrop:true,width:'20%',data:company});

    dialogRef.afterClosed().subscribe( async result => {
      if(result === null || result === undefined || result == false)
      {
       return;
      }

      this.companyService.delete(company).subscribe({
        next: (value) =>  {
          this.showSuccess("Company Deleted")
          this.loadCompanies();
        },
        error: (err) => {
          this.showError(err.message)
        },
      });

  });

  }

  showSuccess(message:string): void {
    this.snackBar.openFromComponent(SuccessComponent, {
      duration: 20 * 1000,
      data: message,
      panelClass: ['success-snackbar']
    });
  }

  showError(message:string):void{
    this.snackBar.openFromComponent(ErrorComponent, {
      duration: 20 * 1000,
      data: message,
      panelClass: ['error-snackbar']
    });
  }

}
