<header>
<app-nav></app-nav>
</header>

<main>
  <mat-sidenav-container class="example-container">
    <mat-sidenav #sidenav mode="side" opened class="example-sidenav" style="padding-block:0.5em"
                 [fixedInViewport]="false" [fixedTopGap]="0"
                 [fixedBottomGap]="0">
     <ul class="sub-nav">
      <li><a [routerLink]="[ '',{ outlets: {  aux: ['dashboard'] } }]" routerLinkActive="active-link" class="sub-nav_item fa fa-chart-area"><span>Dashboard</span></a></li>
      <li><a [routerLink]="[ '',{ outlets: { aux: ['coupes'] } }]" routerLinkActive="active-link" class="sub-nav_item fa fa-vector-square">  <span>Coupes</span></a></li>
      <li *ngIf="user.checkPermissions()"><a [routerLink]="[ '',{ outlets: { aux: ['devices'] } }]" routerLinkActive="active-link" class="sub-nav_item fa fa-crutch"><span>Devices</span></a></li>
      <li *ngIf="user.checkPermissions()"><a [routerLink]="[ '',{ outlets: { aux: ['companies'] } }]" routerLinkActive="active-link" class="sub-nav_item fa fa-house-user"><span>Companies</span></a></li>
      <li *ngIf="user.checkPermissions()"><a [routerLink]="[ '',{ outlets: { aux: ['users'] } }]" routerLinkActive="active-link" class="sub-nav_item fa fa-user"><span>Users</span></a></li>

     </ul>
    </mat-sidenav>

    <mat-sidenav-content >
      <router-outlet style="display: none;" name="aux"></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</main>
