import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-restore-conflicted-tree-point-dialog',
  templateUrl: './restore-conflicted-tree-point-dialog.component.html',
  styleUrls: ['./restore-conflicted-tree-point-dialog.component.css']
})
export class RestoreConflictedTreePointDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
