import { AfterViewInit, Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { AuthService } from './services/auth.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit{
  title = 'Geo Tree';


  userSub$: Subscription;
  isAuthenticated: boolean = false;




  constructor(private authService: AuthService) {

  }

  ngOnInit(): void {
    this.authService.autoLogin();

    this.userSub$ = this.authService.user.subscribe(user =>
    {
      this.isAuthenticated = !user ? false : true;

    });
  }


  ngAfterViewInit() {

  }



}
