<h2 mat-dialog-title>Edit User</h2>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="editUserForm" (ngSubmit)="onSubmit()">
    <section class="grid">
      <mat-form-field appearance="outline">
        <mat-label>First name</mat-label>
        <input
          autocomplete="given-name"
          formControlName="firstName"
          matInput
          placeholder="Luke"
        />
        <mat-error *ngIf="editUserForm.get('firstName').errors?.['required']"
          >This field is required!</mat-error
        >
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Surname</mat-label>
        <input
          autocomplete="family-name"
          formControlName="surname"
          matInput
          placeholder="Bennett"
        />
        <mat-error *ngIf="editUserForm.get('surname').errors?.['required']"
          >This field is required!</mat-error
        >
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input
          autocomplete="email"
          formControlName="email"
          matInput
          placeholder="luke.bennett@eskmapping.com.au"
        />
        <mat-error
          class="form-error"
          *ngIf="
            !editUserForm.get('email').valid &&
            editUserForm.get('email').touched
          "
        >
          <span *ngIf="editUserForm.get('email').errors?.['required']"
            >This field is required!</span
          >
          <span *ngIf="editUserForm.get('email').errors?.['email']"
            >Please enter a valid email address.</span
          >
          <span *ngIf="editUserForm.get('email').errors?.['emailExists']"
            >An account with that email already Exists!</span
          >
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>roles</mat-label>
        <mat-select formControlName="roles" multiple>
          <mat-option *ngFor="let role of roles" [value]="role.name">{{
            role.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <section class="grid">
        <mat-checkbox class="example-margin" formControlName="isEnabled"
        >Active</mat-checkbox
      >

      <mat-checkbox class="example-margin" formControlName="isEmailConfirmed"
        >Email confirmed</mat-checkbox
      >
      </section>


      <mat-form-field appearance="outline">
        <mat-label>Company</mat-label>
        <mat-select formControlName="company">
          <option [value]="null" disabled>Choose company</option>
          <mat-option *ngFor="let company of companies" [value]="company.name">
            {{ company.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </section>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <footer>
    <button
      type="button"
      class="button button--black-outline"
      [mat-dialog-close]="false"
    >
      Cancel
    </button>
    <button
      type="button"
      class="button button--blue"
      [disabled]="!editUserForm.dirty"
      (click)="onSubmit()"
      type="submit"
    >
      Update
    </button>
  </footer>
</mat-dialog-actions>
