import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Feature, View } from 'ol';
import LayerGroup from 'ol/layer/Group';
import VectorLayer from 'ol/layer/Vector';
import { ProjectionLike } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { Company, CompanyService } from 'src/app/services/company.service';
import { base_map_defs } from 'src/app/_helpers/basemaps';
import { CustomValidators } from 'src/app/_helpers/customValidators';
import { populateLayerGroup } from 'src/app/_helpers/LayerBuilder';
import { mapProjEPSG } from 'src/app/_helpers/projection_setup';
import { ImportShapeFileDialogComponent } from '../../import-shape-file-dialog/import-shape-file-dialog.component';
import OlMap from 'ol/Map';
import * as olProj from 'ol/proj';
import { Coupe } from 'src/app/components/main/coupes/coupes.component';
import VectorImageLayer from 'ol/layer/VectorImage';
import { createXYZ } from 'ol/tilegrid';
import { tile as tileStrategy } from 'ol/loadingstrategy';
import { ConvertGeoJsonToFeatureCollection } from 'src/app/_helpers/transformations';

@Component({
  selector: 'app-edit-coupe',
  templateUrl: './edit-coupe.component.html',
  styleUrls: ['./edit-coupe.component.css']
})
export class EditCoupeComponent implements OnInit {

  editCoupeForm: UntypedFormGroup;

  companies: Array<Company> = [];
  user: User;

  proj : ProjectionLike | null = olProj.get(mapProjEPSG);
  baseMapGroup = new LayerGroup({
    layers: [],
    title: 'Background Maps',
    fold: 'open',
  } as any);

  companySub$: Subscription = new Subscription();
  userSub$: Subscription = new Subscription();
  @ViewChild('mapElementRef1', { static: true }) mapElementRef1: ElementRef;

  constructor(public dialogRef: MatDialogRef<ImportShapeFileDialogComponent>,@Inject(MAT_DIALOG_DATA) public data: Coupe ,private authService:AuthService, private companyService: CompanyService) {

    this.companySub$ = this.companyService.companies.subscribe(companies => {
      this.companies = companies
    });

    this.userSub$ = this.authService.user.subscribe(user => {
      this.user = user;
    });

   }


  ngOnInit(): void {
      this.dialogRef.disableClose = true;
      populateLayerGroup(base_map_defs, this.baseMapGroup, ()=>{});

      this.editCoupeForm = new UntypedFormGroup({
        'coupeCode': new UntypedFormControl(null, CustomValidators.required),
        'coupeName': new UntypedFormControl(null, CustomValidators.required),
        'treeSpecies': new UntypedFormControl(null, CustomValidators.required),
        'coupeSeed': new UntypedFormControl(null, CustomValidators.required),
        'targetSte': new UntypedFormControl(null, CustomValidators.required),
        'rowSpacing': new UntypedFormControl(null, CustomValidators.required),
        'interrowSpacing': new UntypedFormControl(null, CustomValidators.required),
        'nursery': new UntypedFormControl(null, CustomValidators.required),
        'netting': new UntypedFormControl(null, CustomValidators.required),
        'fertilizer': new UntypedFormControl(null, CustomValidators.required),
        'company': new UntypedFormControl(null, CustomValidators.required),
    });

    this.editCoupeForm.get('company').setValue(this.user.companyID);

    this.editCoupeForm.patchValue(this.data);




  }

  ngAfterViewInit(): void {
    let map = new OlMap({
      layers: [
        this.baseMapGroup,
      ],
      view: new View({
        center: [494095, 5343594],
        projection: this.proj == null ? null : this.proj,
        zoom: 5,
        maxZoom: 23,
      }),
      pixelRatio: window.devicePixelRatio,
      maxTilesLoading: 256,
      controls: [
      ],

    });
map.setTarget(this.mapElementRef1.nativeElement);

map.updateSize();

const vectorSource = new VectorSource({
  loader: async (extent, resolution, projection, success, failure) => {
    let encodedComponent = encodeURIComponent(
      '{"xmin":' +
        extent[0] +
        ',"ymin":' +
        extent[1] +
        ',"xmax":' +
        extent[2] +
        ',"ymax":' +
        extent[3] +
        ',"spatialReference":{"wkid":28355}}'
    );

const url = `https://dev.geotree.eskmapping.com.au/geoserver/GeoTree/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=cite%3ACoupes&CQL_FILTER=CoupeID%20IN%20(${this.data.id})&srsname=EPSG:28355&outputFormat=application%2Fjson`;

    try {
      let response = await fetch(url);

      if (response.ok) {
        let featuresJson = await response.json();



        const features = ConvertGeoJsonToFeatureCollection(featuresJson);

        if (features.length > 0) {
          features.forEach((element) => {
            let props = element.getProperties();
            element.setId(props.OBJECTID);
          });

          vectorSource.addFeatures(features);
        }

        success(features);
      }
    } catch (error) {
      failure();
    }
  },
  strategy: tileStrategy(
    createXYZ({
      tileSize: 1028,
    })
  ),
});

let r = new VectorImageLayer({
  imageRatio: 2,
  source: vectorSource,
});

map.addLayer(r);

map.getView().fit([this.data.extent.left, this.data.extent.top, this.data.extent.right, this.data.extent.bottom],{padding:[25,25,25,25]});
}


  onSubmit(){
    Object.assign(this.data, this.editCoupeForm.value)
    this.dialogRef.close(this.data);
  }
}


