import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { Observable } from "rxjs";

export class CustomValidators extends Validators {

  static passwordsMatch: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    const password = control.get('password');
    const confirmPassword = control.get('confirmPassword');

    return password?.value === confirmPassword?.value ? null : { notMatched: true };
  };


  static passwordHasUpper: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    let valid = true;

    const password = control.value;

    valid = /[A-Z]/.test(password);

    return valid ? null : { noUpper: true };
  };

  static passwordHasSpecial: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    let valid = true;

    const password = control.value;

    valid = /\W/.test(password);

    return valid ? null : { noSpecial: true };
  };

}
