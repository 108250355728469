import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { CompanyTypes, CompanyService, Company } from 'src/app/services/company.service';
import { CustomValidators } from 'src/app/_helpers/customValidators';
import { AddDeviceDialogComponent } from '../../add-device-dialog/add-device-dialog.component';

@Component({
  selector: 'app-edit-company',
  templateUrl: './edit-company.component.html',
  styleUrls: ['./edit-company.component.css']
})
export class EditCompanyComponent implements OnInit {


  editCompanyForm: UntypedFormGroup;

  private companySub$: Subscription;

  public companyTypes: Array<CompanyTypes>;

  constructor(private companyService:CompanyService,public dialogRef: MatDialogRef<AddDeviceDialogComponent>,@Inject(MAT_DIALOG_DATA) private company: Company) {

    this.companyService.getAllCompanyTypes().subscribe({
      next: (value) => {this.companyTypes = value}
    })

    this.editCompanyForm = new UntypedFormGroup({
      'name': new UntypedFormControl(null, CustomValidators.required),
      'type': new UntypedFormControl(null, CustomValidators.required)});

    this.editCompanyForm.patchValue(company);

   }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
      this.companySub$?.unsubscribe();
  }

  onSubmit(){
    Object.assign(this.company, this.editCompanyForm.value);

    this.dialogRef.close(this.company);
  }
}
