<h2 mat-dialog-title>Add User</h2>
<mat-dialog-content class="mat-typography">

  <form
    [formGroup]="addUserForm"
    (ngSubmit)="onSubmit()"
  >
  <section class="grid">
    <mat-form-field appearance="outline">
      <mat-label>First name</mat-label>
      <input autocomplete="given-name" formControlName="firstName" matInput placeholder="Luke">
      <mat-error *ngIf="addUserForm.get('passwordData.password').errors?.['required']">This field is required!</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Surname</mat-label>
      <input autocomplete="family-name" formControlName="surname" matInput placeholder="Bennett">
      <mat-error *ngIf="addUserForm.get('passwordData.password').errors?.['required']">This field is required!</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Email</mat-label>
      <input autocomplete="email" formControlName="email" matInput placeholder="luke.bennett@eskmapping.com.au">
      <mat-error class="form-error" *ngIf="!addUserForm.get('email').valid && addUserForm.get('email').touched ">

        <span *ngIf="addUserForm.get('email').errors?.['required']">This field is required!</span>
        <span *ngIf="addUserForm.get('email').errors?.['email']">Please enter a valid email address.</span>
        <span *ngIf="addUserForm.get('email').errors?.['emailExists']">An account with that email already Exists!</span>
       </mat-error>
    </mat-form-field>

    <section formGroupName="passwordData" class="grid">
      <mat-form-field appearance="outline">
        <mat-label>Password</mat-label>
        <input autocomplete="new-password" formControlName="password" type="password" matInput>
        <mat-error *ngIf="addUserForm.get('passwordData.password').errors?.['required']">This field is required!</mat-error>

      </mat-form-field>
      <span *ngIf="!addUserForm.get('passwordData.password').valid && addUserForm.get('passwordData.password').touched">
          Password must contain:
          <ul>
            <li *ngIf="addUserForm.get('passwordData.password').errors?.['noUpper']">One or more uppercase characters.</li>
            <li *ngIf="addUserForm.get('passwordData.password').errors?.['noSpecial']">One or more special characters !$&. </li>
            <li *ngIf="addUserForm.get('passwordData.password').errors?.['minlength']">8 or more characters.</li>
          </ul>
      </span>
      <mat-form-field appearance="outline">
        <mat-label>Confirm password</mat-label>
        <input autocomplete="new-password" formControlName="confirmPassword" type="password" matInput>
        <mat-error  *ngIf="!addUserForm.get('passwordData').valid && addUserForm.get('passwordData.password').touched  && addUserForm.get('passwordData.confirmPassword').touched">
          <span *ngIf="addUserForm.get('passwordData').errors?.['notMatched']">Your passwords don't match</span>
         </mat-error>
      </mat-form-field>
    </section>


    <mat-form-field appearance="outline">
      <mat-label>Company</mat-label>
      <mat-select formControlName="company">
        <option [value]="null" disabled>Choose company</option>
        <mat-option *ngFor="let company of companies" [value]="company.name">
          {{company.name}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="addUserForm.get('passwordData.password').errors?.['required']">This field is required!</mat-error>
    </mat-form-field>
  </section>

  </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <footer>
    <button type="button" class="button button--black-outline" [mat-dialog-close]="false">
      Cancel
    </button>
    <button
      type="button"
      class="button button--blue"
      [disabled]="!addUserForm.valid"
      (click)="onSubmit()"
      type="submit"
    >
      Save
    </button>
  </footer>
</mat-dialog-actions>
