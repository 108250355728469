import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CustomValidators } from 'src/app/_helpers/customValidators';
import { EditUserComponent } from '../edit-user/edit-user.component';

@Component({
  selector: 'app-reset-password-admin',
  templateUrl: './reset-password-admin.component.html',
  styleUrls: ['./reset-password-admin.component.css']
})
export class ResetPasswordAdminComponent implements OnInit {

  adminNewPasswordForm: UntypedFormGroup
  constructor(public dialogRef: MatDialogRef<EditUserComponent>) { }

  ngOnInit(): void {

    this.adminNewPasswordForm = new UntypedFormGroup({
      'password': new UntypedFormControl(null, [CustomValidators.required]),
      'rptPassword': new UntypedFormControl(null,CustomValidators.required)
    });


  }


  onSubmit(){

    this.dialogRef.close(this.adminNewPasswordForm.get('password').value);
  }

}
