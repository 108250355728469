<h2 mat-dialog-title>Device Not Found</h2>
<mat-dialog-content class="mat-typography">
<p>The following device <span>{{deviceSerial}}</span> was not found in our system, it will need to be added before you can import data.</p>
<p>Would you like to add it now?</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <footer>
    <button type="button" class="button button--black-outline" [mat-dialog-close]="false">
      No
    </button>
    <button class="button button--brand" [mat-dialog-close]="true">
      Yes
    </button>
  </footer>
</mat-dialog-actions>
