import { HttpClient, HttpHeaders, JsonpClientBackend } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, catchError, Observable, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

export interface UserDTO{
userID: any;
firstName: any;
surname: any;
email: any;
isEmailConfirmed: any;
isEnabled: any;
company: any;
lastLoginDate: any;
roles : any;
}


export interface AdminPasswordResetDTO {
  email:string,
  password:string
}
@Injectable({
  providedIn: 'root'
})
export class UserService  {

  private API_ENDPOINT: string;

  constructor(private http: HttpClient) {
    this.API_ENDPOINT = `${environment.apiURL}user/`;

  }

  public getAll()
  {
    return this.http.get<Array<UserDTO>>(this.API_ENDPOINT + 'getAll',httpOptions);
  }

  public add(user:UserDTO)
  {
    return this.http.post<UserDTO>(this.API_ENDPOINT + 'add',user,httpOptions).pipe(catchError(this.handelError));
  }

  public update(user:UserDTO)
  {
    return this.http.post<UserDTO>(this.API_ENDPOINT + 'update',user,httpOptions).pipe(catchError(this.handelError));
  }

  public delete(user:UserDTO)
  {
    return this.http.post<UserDTO>(this.API_ENDPOINT + 'delete',user,httpOptions).pipe(catchError(this.handelError));
  }

  public adminPasswordReset(user: AdminPasswordResetDTO)
  {
    return this.http.post<UserDTO>(this.API_ENDPOINT + 'adminPasswordReset',user,httpOptions).pipe(catchError(this.handelError));
  }

  handelError(errorRes)
  {
   let errorMessage = 'An unknown error occurred!'

   if(!errorRes.error||!errorRes.error.result)
   {
     return throwError(() => { return new Error(errorMessage)});
   }

   switch (errorRes.error.result) {
     case 'INVALID_CREDENTIALS':
       errorMessage = "Invalid Login Credentials";
       break;
     case 'EMAIL_EXISTS':
       errorMessage = "An Account Already Exists With That Email";
       break;

     default:
       break;
   }

   return throwError(() => { return new Error(errorMessage)});

  }
}


