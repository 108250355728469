
<header >
  <h1>Manage Coupes</h1>
  <section class="grid grid--gap grid--column">
    <button class="button button--brand" (click)="add()">Import Coupe</button>
    <a type="button" style="text-decoration: none;" [routerLink]="[ '',{ outlets: {primary:'map',aux:null} }]" class="button button--blue">Launch Map</a>
  </section>
</header>
<main class="card">

  <div class="user-management_table-container">
    <div class="table-responsive">
      <table mat-table [dataSource]="dataSource" class="user-management_table">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="coupeCode">
          <th mat-header-cell *matHeaderCellDef> Code </th>
          <td mat-cell *matCellDef="let element"> {{element.coupeCode}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="coupeName">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.coupeName}} </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="company">
          <th mat-header-cell *matHeaderCellDef> Company </th>
          <td mat-cell *matCellDef="let element"> {{element.company}} </td>
        </ng-container>

        <ng-container matColumnDef="treeSpecies">
          <th mat-header-cell *matHeaderCellDef> Species </th>
          <td mat-cell *matCellDef="let element"> {{element.treeSpecies}} </td>
        </ng-container>

        <ng-container matColumnDef="coupeSeed">
          <th mat-header-cell *matHeaderCellDef> Seed </th>
          <td mat-cell *matCellDef="let element"> {{element.coupeSeed}} </td>
        </ng-container>

        <ng-container matColumnDef="targetSte">
          <th mat-header-cell *matHeaderCellDef> Target Ste </th>
          <td mat-cell *matCellDef="let element"> {{element.targetSte}} </td>
        </ng-container>

        <ng-container matColumnDef="rowSpacing">
          <th mat-header-cell *matHeaderCellDef> Row Spacing </th>
          <td mat-cell *matCellDef="let element"> {{element.rowSpacing}} </td>
        </ng-container>

        <ng-container matColumnDef="interrowSpacing">
          <th mat-header-cell *matHeaderCellDef> Inter-row Spacing </th>
          <td mat-cell *matCellDef="let element"> {{element.interrowSpacing}} </td>
        </ng-container>

        <ng-container matColumnDef="nursery">
          <th mat-header-cell *matHeaderCellDef> Nursery </th>
          <td mat-cell *matCellDef="let element"> {{element.nursery}} </td>
        </ng-container>

        <ng-container matColumnDef="netting">
          <th mat-header-cell *matHeaderCellDef> Netting </th>
          <td mat-cell *matCellDef="let element"> {{element.netting}} </td>
        </ng-container>

        <ng-container matColumnDef="fertilizer">
          <th mat-header-cell *matHeaderCellDef> Fertilizer </th>
          <td mat-cell *matCellDef="let element"> {{element.fertilizer}} </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell  *matHeaderCellDef > Actions </mat-header-cell>
          <mat-cell class="grid-column" *matCellDef="let row" >
               <button class="button button--blue" (click)="update(row)" >Edit</button>
               <button *ngIf="user.checkIsAdmin()" class="button button--danger" (click)="delete(row)" >Delete</button>
          </mat-cell>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
    aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>


</main>
