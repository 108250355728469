import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Company, CompanyService } from 'src/app/services/company.service';
import { DeviceColors, DeviceService } from 'src/app/services/device.service';
import { UserDTO, UserService } from 'src/app/services/user.service';
import { CustomValidators } from 'src/app/_helpers/customValidators';
import { AddUserDialogComponent } from '../add-user-dialog/add-user-dialog.component';

@Component({
  selector: 'app-add-device-dialog',
  templateUrl: './add-device-dialog.component.html',
  styleUrls: ['./add-device-dialog.component.css']
})
export class AddDeviceDialogComponent implements OnInit,OnDestroy {

  addDeviceForm: UntypedFormGroup;

  private companySub$: Subscription;

  private userSub$: Subscription;

  public companies: Array<Company>;

  public users: Array<UserDTO>;

  public colors: Array<DeviceColors> = [];

  constructor(private companyService:CompanyService,private userService: UserService,private deviceService:DeviceService, public dialogRef: MatDialogRef<AddDeviceDialogComponent>,private dialog:MatDialog, @Inject(MAT_DIALOG_DATA) public deviceSerial: string = '') {

    this.companySub$ = companyService.companies.subscribe(companies => {
      this.companies = companies
    });
    userService.getAll().subscribe(_users => {
      this.users = _users
    });


    deviceService.getAvailableColors().subscribe(colors => {
      this.colors = colors;
    })

    this.addDeviceForm = new UntypedFormGroup({
      'serial': new UntypedFormControl(null, CustomValidators.required),
      'number': new UntypedFormControl(null),
      'company': new UntypedFormControl(null, CustomValidators.required),
      'user': new UntypedFormControl(null, CustomValidators.required),
      'color': new UntypedFormControl(null, CustomValidators.required)}
      );

      this.addDeviceForm.get('serial').setValue(this.deviceSerial);


      this.addDeviceForm.get('user').valueChanges.subscribe(value =>
        {
           if(value == 'createUser')
           {
            this.addUser()
           }
        })

   }


   addUser()
   {
     const dialogRef = this.dialog.open(AddUserDialogComponent,{hasBackdrop:true,width:'20%'});

     dialogRef.afterClosed().subscribe( async result => {
      if(result === null || result === undefined || result === false)
      {
       return;
      }

       this.userService.add(result).subscribe({
         next: (value) =>  {
          this.userService.getAll().subscribe(_users => {
            this.users = _users
            this.addDeviceForm.get('user').setValue(result.email)
          });
         },
         error: (err) => {

         },
       });

     });
   }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
      this.companySub$?.unsubscribe();
  }

  onSubmit(){
    this.dialogRef.close(this.addDeviceForm.value);
  }

}
