import { Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { Coupe } from '../../main/coupes/coupes.component';

@Component({
  selector: 'app-coupe-card',
  templateUrl: './coupe-card.component.html',
  styleUrls: ['./coupe-card.component.css']
})
export class CoupeCardComponent implements OnInit {

  @Input() coupe;

 hovered = false;


  @Output() zoomTo = new EventEmitter<Coupe>();

  @Output() update = new EventEmitter<Coupe>();

  constructor(public elementRef: ElementRef) {
   }

  ngOnInit(): void {
    console.log(this.coupe)
  }


}
