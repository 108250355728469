import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit, OnDestroy {

  private authenticationSub$: Subscription;

  public user: User;

  constructor(private authService:AuthService, private routerService: Router) {

this.authenticationSub$ = authService.user.subscribe(user => {
  this.user = user;
})
   }

  ngOnInit(): void {
  }
  ngOnDestroy(): void {
    this.authenticationSub$.unsubscribe();
  }

  logout(): void
  {
    this.authService.logout();
    this.routerService.navigate([{ outlets: {primary:'login',aux:null} }]);
  }
}
