import { AfterContentInit, AfterViewInit, Component, ContentChildren, Directive, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { delay, map } from 'rxjs';


@Component({
  selector: 'sidebar-tabs',
  template:`
    <ng-content></ng-content>
  `
})
export class SidebarTabs {
  @HostBinding('class') class = 'sidebar-tabs';
}

@Component({
  selector: 'sidebar-panes',
  template:`
    <ng-content></ng-content>
  `
})
export class SidebarPanes {
  @HostBinding('class') class = 'sidebar-content';
}




@Directive({
  selector: '[sidebarTab]'
})
export class SidebarTabDirective implements AfterViewInit {

  @Input() sidebarTargetPane: string;
  @Output() open = new EventEmitter<string>();
  @Input() startOpen: boolean = false;
  @HostBinding('class.active') active: boolean = this.startOpen;

  constructor(private eleRef: ElementRef) {
  }

  ngOnInit() {

    let sidebarTabElement = this.eleRef.nativeElement as HTMLElement;

    let hoverToolTipElement = sidebarTabElement.querySelector('span');

    sidebarTabElement.addEventListener('mouseenter',()=> {
      hoverToolTipElement.style.opacity = '1';
    });


    sidebarTabElement.addEventListener('mouseleave',()=> {
      hoverToolTipElement.style.opacity = '0';
    })

    sidebarTabElement.addEventListener('click', (event) => {
      event.preventDefault();
      this.active = !this.active;
      this.open.emit(this.sidebarTargetPane);
    })
  }

  ngAfterViewInit(){
    if(this.startOpen)
    {
      this.active = !this.active;
      this.open.emit(this.sidebarTargetPane)
    }
  }

}


@Directive({
  selector: '[sidebarPane]'
})
export class SidebarPaneDirective {

  @Input() sidebarPaneName: string;
  @Input() startOpen: boolean = false;

  @HostBinding('class') class = 'sidebar-pane';

  @HostBinding('class.active') active: boolean = this.startOpen;

  constructor(public eleRef: ElementRef) {


  }

  ngOnInit() {

  }
}



@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit,AfterContentInit {

  @ContentChildren(SidebarTabDirective,{descendants:true}) sidebarTabs: QueryList<SidebarTabDirective>;
  @ContentChildren(SidebarPaneDirective,{descendants:true}) sidebarContent: QueryList<SidebarPaneDirective>;

  @HostBinding('class') class = 'sidebar sidebar-right';

  @HostBinding('class.collapsed') collapsed: boolean = true;

  sidebarItems: Map<string, {tab: SidebarTabDirective, pane: SidebarPaneDirective}> = new Map();


  constructor() { }

  ngOnInit(): void {
  }

  ngAfterContentInit(): void {
    this.initSidebar();
  };
/**
 * We're looping through the tabs, finding the pane that matches the tab's target pane name, and then
 * adding the tab and pane to the sidebarItems map
 */
  private initSidebar()
  {
    this.sidebarTabs.forEach(tab => {

      let pane = this.sidebarContent.find(item => {
        return item.sidebarPaneName == tab.sidebarTargetPane;
      });

      this.sidebarItems.set(tab.sidebarTargetPane, {tab:tab, pane:pane});

      tab.open.subscribe(targetName => {
        this.open(targetName);
      })

    });
  }


/**
 * If the pane is already open, close it. If it's not open, close all other open panes and open this
 * one
 * @param {string} targetName - The name of the pane to open.
 * @returns the value of the variable 'collapsed'
 */
  open(targetName: string): void
  {
    let pane = this.sidebarItems.get(targetName);

    this.closeOtherOpenPanes(targetName)

    if(pane.pane.active)
    {
      pane.pane.active = false;
      this.collapsed = true;
      return;
    }

    pane.pane.active = true;
    this.collapsed = false;

  }

 /**
  * It loops through all the sidebar items, and if the key is not the active target name, it sets the
  * pane and tab to inactive
  * @param activeTargetName - The name of the sidebar item that was clicked.
  */
  closeOtherOpenPanes(activeTargetName)
  {
    this.sidebarItems.forEach((sidebarItem,key) => {
      if(key == activeTargetName)
      return;

      sidebarItem.pane.active = false;
      sidebarItem.tab.active = false;

    })
  }

}


