import {
  AfterViewInit,
  Component,
  HostBinding,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Feature } from 'ol';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/company.service';
import { CoupeService } from 'src/app/services/coupe.service';
import { ConvertShapeFilesToFeatureCollection } from 'src/app/_helpers/shapeParser';
import { ConvertFeatureToGeoJson } from 'src/app/_helpers/transformations';
import { DeleteCoupeComponent } from '../../shared/dialogs/coupe/delete-coupe/delete-coupe.component';
import { EditCoupeComponent } from '../../shared/dialogs/coupe/edit-coupe/edit-coupe.component';
import { ImportShapeFileDialogComponent } from '../../shared/dialogs/import-shape-file-dialog/import-shape-file-dialog.component';
import { ErrorComponent } from '../../shared/snackbar/error/error.component';
import { SuccessComponent } from '../../shared/snackbar/success/success.component';

export class Coupe {
  id: number;
  coupeCode: string;
  coupeName: string;
  company: string;
  extent: any;
  treeSpecies: string;
  coupeSeed: string;
  targetSte: number;
  rowSpacing: number;
  interrowSpacing;
  nursery: number;
  netting: number;
  fertilizer: number;
}

@Component({
  selector: 'app-coupes',
  templateUrl: './coupes.component.html',
  styleUrls: ['./coupes.component.css'],
})
export class CoupesComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['coupeCode', 'coupeName', 'company' , 'treeSpecies','coupeSeed','targetSte','rowSpacing','interrowSpacing','nursery','netting','fertilizer','actions'];
user:User;
  data: Array<Coupe>;
  dataSource: any;
  @HostBinding('class') class = 'main-pane';

  constructor(
    private router: Router,
    private coupeService: CoupeService,
    private companyService: CompanyService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    public authService:AuthService
  ) {}

  ngOnInit(): void {
    this.coupeService.getAll().subscribe((coupes: Array<Coupe>) => {
      this.dataSource = new MatTableDataSource<Coupe>(coupes);
    });

    this.authService.user.subscribe(user => this.user = user);
  }

  add() {
    let test = document.createElement('input');
    test.type = 'file';
    test.onchange = async (event: any) => {
      let features = await ConvertShapeFilesToFeatureCollection(
        event.target.files
      );
      this.shapeFileImported(features);
    };

    test.click();
  }

  shapeFileImported(features: Array<Feature<any>>) {
    const dialogRef = this.dialog.open(ImportShapeFileDialogComponent, {
      hasBackdrop: true,
      width: '80%',
      data: features[0],
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      let data = new FormData();
      if (result instanceof Feature<any>) {
        data.append('geoJson', ConvertFeatureToGeoJson(result));
        data.append('companyID', result.getProperties()['company']);
        this.coupeService.add(data).subscribe((response) => {
          this.showSuccess('Coupe successfully imported');
        });
      }
    });
  }

  update(coupe:Coupe)
  {
    const dialogRef = this.dialog.open(EditCoupeComponent, {
      hasBackdrop: true,
      width: '80%',
      data: coupe,
    });

    dialogRef.afterClosed().subscribe(async (result) => {

      if(result != null && result != false)
      {
        this.coupeService.update(result).subscribe({
          next: (value) => {
            this.showSuccess("Coupe updated");
            this.coupeService.getAll().subscribe((coupes: Array<Coupe>) => {
              this.dataSource = new MatTableDataSource<Coupe>(coupes);
            });
          }
        });

      }
    });
  }


  delete(coupe:Coupe)
  {
    const dialogRef = this.dialog.open(DeleteCoupeComponent,{hasBackdrop:true,width:'20%',data:coupe});

    dialogRef.afterClosed().subscribe( async result => {
      if(result === null || result === undefined || result == false)
      {
       return;
      }

      this.coupeService.delete(coupe).subscribe({
        next: (value) =>  {
          this.showSuccess("Coupe deleted")
          this.coupeService.getAll().subscribe((coupes: Array<Coupe>) => {
            this.dataSource = new MatTableDataSource<Coupe>(coupes);
          });
        },
        error: (err) => {
          this.showError(err.message)
        },
      });

  });

  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  testing() {
    this.router.navigate(['/map']);
  }

  filterResults(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;

    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  showSuccess(message: string): void {
    this.snackBar.openFromComponent(SuccessComponent, {
      duration: 5 * 1000,
      data: message,
      panelClass: ['success-snackbar'],
    });
  }

  showError(message: string): void {
    this.snackBar.openFromComponent(ErrorComponent, {
      duration: 5 * 1000,
      data: message,
      panelClass: ['error-snackbar'],
    });
  }
}
