import * as jsZip from 'jszip';
import { Feature } from 'ol';
import * as Shape2Geojson from 'shapefile-to-geojson';
import { ConvertGeoJsonToFeatureCollection } from './transformations';

export async function ConvertShapeFilesToFeatureCollection(files: FileList): Promise<Array<Feature<any>>>
{
  let geojson,featureCollection = [];

  let zipFiles = Array.from(files).filter( file => {
    return file.name.endsWith('.zip');
  });

let otherFiles = Array.from(files).filter( file => {
  return !file.name.endsWith('.zip');
});

  if(otherFiles.length > 1 && (otherFiles.length % 2 != 0))
  {

  }

  if(otherFiles.length == 1)
  {
   throw new Error(`Only 1 file was uploaded, please upload both a .shp file and a .dbf file.`);
  }

  if(zipFiles.length >= 1)
  {

    for (let index = 0; index < zipFiles.length; index++) {
      const file = zipFiles[index];
      let shpFile, dbfFile;

      const zipClient = new jsZip();

      let data = await zipClient.loadAsync(file);

    for (const property in data.files) {
      if(property.endsWith(".shp"))
      {
        shpFile = await data.files[property].async('arraybuffer');
      }
      if(property.endsWith(".dbf"))
      {
        dbfFile = await data.files[property].async('arraybuffer');
      }
    }

    if(shpFile == null || dbfFile == null)
    {
      throw new Error(`The zip file does not contain either a shape file or a dbf file`);
    }

    geojson = await Shape2Geojson.parseFiles(shpFile,dbfFile);
    console.log(geojson);
    featureCollection.push(...ConvertGeoJsonToFeatureCollection(geojson));

    }

  }

  if(otherFiles.length > 1)
  {

    let shapeList =[], dbfList = [];

    for (let index = 0; index < otherFiles.length; index++) {

      let file = otherFiles[index];

      if(file.name.endsWith('.dbf'))
      {
        dbfList.push(await file.arrayBuffer());
      }
      else if (file.name.endsWith('.shp'))
      {
        shapeList.push(await file.arrayBuffer());
      }
    }

    if(shapeList.length != dbfList.length)
    {
      throw new Error(`There was a miss-match between the amount of dbf files ${dbfList.length} and shape files ${shapeList.length}`);
    }
    geojson = await Shape2Geojson.parseFiles(shapeList[0],dbfList[0]);
    console.log(geojson);
    featureCollection.push(...ConvertGeoJsonToFeatureCollection(geojson));
  }

  return featureCollection;
}
