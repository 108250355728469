/*
 *
 *  Adds callback handlers and typescript to  "sidebar-v2": "github:umbe1987/sidebar-v2#ol5-sidebar",
 *
 * TS conversion only partial
 *
 *  Luke Bennett
 *
 */

import Map from 'ol/Map';
import { Control } from 'ol/control';

interface Options {
  target: string | HTMLElement;
  position?: string;
  openCallback?: Function
}

export class SidebarEsk extends Control {
  position: string;
  private container: any = null;
  private tabItems: any = null;
  private panes: Array<any> = [];
  private closeButtons: Array<any> = [];
  private openCallback: Function = null;
  private closeCallback: Function = null;

  constructor(options: Options) {
    super({
      element:
        typeof options.target === 'string'
          ? document.getElementById(options.target)
          : options.target,
    });

    this.position = options.position ? options.position : 'right';

    this.openCallback = options.openCallback ? options.openCallback : null

    this.element.classList.add('sidebar-' + this.position);

    for (let i = this.element.children.length - 1; i >= 0; i--) {
      let child = this.element.children[i];
      if (
        child.tagName === 'DIV' &&
        child.classList.contains('sidebar-content')
      ) {
        this.container = child;
      }
    }

    this.tabItems = Array.from(this.element.querySelectorAll(
      'ul.sidebar-tabs > li, .sidebar-tabs > ul > li'
    ));

    for (let i = this.tabItems.length - 1; i >= 0; i--) {
      this.tabItems[i]._sidebar = this;
    }

    // Find sidebar > div.sidebar-content > div.sidebar-pane
    for (let i = this.container.children.length - 1; i >= 0; i--) {
      let child = this.container.children[i];

      if (child.tagName == 'DIV' && child.classList.contains('sidebar-pane')) {
        this.panes.push(child);

        let closeButtons = child.querySelectorAll('.sidebar-close');

        closeButtons.forEach((button) => {
          this.closeButtons.push(button);
        });
      }


    }

    let sidebarTabsElement = document.querySelector('.sidebar-tabs');

    let sidbarTabLists = sidebarTabsElement.querySelectorAll('ul');



  }

  /**
   * Set the map instance the control is associated with.
   * @param {ol.Map} map The map instance.
   */
  setMap(map: Map): void {
    this.setClickEvents();
  }

  private setClickEvents() : void
  {
    for (let i = this.tabItems.length - 1; i >= 0; i--)
    {
      let child = this.tabItems[i];
      var sub = child.querySelector('a');
      if (
        sub.hasAttribute('href') &&
        sub.getAttribute('href').slice(0, 1) == '#'
      )
      {
        sub.onclick = (event: Event) => {
          this.onClick(event);
        };
      }
    }

    for (let i = this.closeButtons.length - 1; i >= 0; i--) {
      let child = this.closeButtons[i];

      child.onclick = (event: Event) => {
        this.onCloseClick();
      };
    }
  }

  open(id: String): SidebarEsk {
    let openID:String, closeID: String;

    for (let i = this.panes.length - 1; i >= 0; i--) {
      let child = this.panes[i];
      if (child.id == id) {
        child.classList.add('active');
        openID = id;
      } else if (child.classList.contains('active'))
        child.classList.remove('active');
    }

    // remove old active highlights and set new highlight
    for (let i = this.tabItems.length - 1; i >= 0; i--) {

      let child = this.tabItems[i];

      if (child.querySelector('a').hash == `#${id}`)
        child.classList.add('active');
      else if (child.classList.contains('active')) {
        child.classList.remove('active');
        closeID = child.id;
      }
    }

    // open sidebar (if necessary)
    if (this.element.classList.contains('collapsed')) {
      this.element.classList.remove('collapsed');
    }

    if (this.openCallback) {
      this.openCallback(openID, closeID);
    }

    return this;
  }

  close(): SidebarEsk {

    for (let i = this.tabItems.length - 1; i >= 0; i--) {
      var child = this.tabItems[i];
      if (child.classList.contains('active')) child.classList.remove('active');
    }

    // close sidebar
    if (!this.element.classList.contains('collapsed')) {
      this.element.classList.add('collapsed');
    }

    if (this.closeCallback) {
      this.closeCallback();
    }

    return this;
  }

  private onClick(evt: Event): void {
    let target: any = evt.target;

    if (target.nodeName === 'I') {
      target = target.parentElement;
    }

    evt.preventDefault();
    if (
      target.classList.contains('active') ||
      target.parentElement.classList.contains('active')
    ) {
      this.close();
    } else if (
      !target.classList.contains('disabled') ||
      target.parentElement.classList.contains('disabled')
    ) {
      this.open(target.hash.slice(1));
    }
  }

  private onCloseClick(): void {
    this.close();
  }

  setOpenCallback = (callback: Function) => {
    if (callback) {
      this.openCallback = async function (...args: any) {
        callback(...args);
      };
    }
  };

  setCloseCallback = function (callback: Function) {
    if (callback) {
      this.closeCallback = async function (...args: any) {
        callback(...args);
      };
    }
  };
}

export default SidebarEsk;
