<section class="overlay">
  <header>
    {{title}}
    <button class="button button--danger" *ngIf="isConflictedTreePoint" (click)="confirmRestore()">Restore Point</button>
  </header>
  <main>
    <table style="width:100%;margin:0">
      <thead>
        <th>Attribute</th>
        <th>Value</th>
      </thead>
      <tbody>
        <tr *ngFor="let row of attributes; index as i">
          <td style="text-align:center">{{row.attribute}}</td>
          <td style="text-align:center">{{row.value}}</td>
        </tr>
      </tbody>
    </table>
  </main>
</section>
