import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivateChild,
  UrlTree
} from '@angular/router';
import { map, Observable, take } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';



@Injectable()
export class RoleGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean | UrlTree > | Promise<boolean> | boolean | UrlTree {
    return this.authService.user.pipe(take(1), map( user => {
      const isAdmin = !!user && user.checkPermissions();
      if(isAdmin)
      {
        return true;
      }
      return this.router.createUrlTree([''])
    }));
  }
}
