import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  map,
  Observable,
  Subject,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { User } from '../models/user.model';

import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { TokenStorageService } from './token-storage.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
interface AuthResponseDataUser {
  refreshToken: string;
  email: string;
  userFullName: string;
  userID: number;
  companyID: number;
}

interface AuthResponseData {
  accessToken: string;
  user: AuthResponseDataUser;
}

interface UserDetails {
  email: string;
  firstName: string;
  surname: string;
  password: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user = new BehaviorSubject<User>(null);

  jwtHelper: JwtHelperService;
  username = '';

  authApi: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private tokenStorageService: TokenStorageService
  ) {
    this.authApi = environment.apiURL;

    this.jwtHelper = new JwtHelperService();
  }

  login(credentials): Observable<AuthResponseData> {
    return this.http
      .post<AuthResponseData>(
        this.authApi + 'login',
        {
          email: credentials.email,
          password: credentials.password,
        },
        httpOptions
      )
      .pipe(
        catchError(this.handelError),
        tap((resData) => {
          const newUser = new User(
            resData.user.userID,
            resData.user.email,
            resData.user.userFullName,
            resData.accessToken,
            resData.user.refreshToken,
            false,
            resData.user.companyID
          );

          newUser.roles = this.getRoles(resData.accessToken);

          this.user.next(newUser);
        })
      );
  }

  register(userDetails: UserDetails): Observable<any> {
    return this.http
      .post(
        this.authApi + 'register',
        {
          email: userDetails.email,
          password: userDetails.password,
          firstName: userDetails.firstName,
          lastName: userDetails.surname,
        },
        httpOptions
      )

  }

  logout() {
    this.tokenStorageService.removeToken();
    this.tokenStorageService.removeUser();

    this.user.next(null);
  }

  refreshToken(token: string, refreshToken: string) {
    return this.http
      .post<any>(
        this.authApi + `token/refresh`,
        {
          accessToken: token,
          refreshToken: refreshToken,
        },
        httpOptions
      )
      .pipe(catchError(this.handelError));
  }

  async autoLogin() {
    const loadedToken = this.tokenStorageService.getToken();
    const loadedUserData = this.tokenStorageService.getUser();

    if (!loadedToken || !loadedUserData) {
      return;
    }

    const userData = JSON.parse(loadedUserData);

    if (this.tokenExpired(loadedToken)) {
      this.refreshToken(loadedToken, userData.refreshToken).subscribe(
        (results) => {
          userData.refreshToken = results.refreshToken;
          const loadedUser = new User(
            userData.userID,
            userData.email,
            userData.userFullName,
            results.accessToken,
            results.refreshToken,
            true,
            userData.companyID
          );
          this.tokenStorageService.saveToken(results.accessToken);
          this.tokenStorageService.saveUser(loadedUser);
          loadedUser.roles = this.getRoles(loadedToken);
          this.user.next(loadedUser);
        }
      );
    } else {
      const loadedUser = new User(
        userData.userID,
        userData.email,
        userData.userFullName,
        loadedToken,
        userData.refreshToken,
        true,
        userData.companyID
      );

      loadedUser.roles = this.getRoles(loadedToken);
      this.user.next(loadedUser);
    }
  }

  private isAdmin(token: string): boolean {
    let decodedToken = this.jwtHelper.decodeToken(token);

    if (decodedToken.role?.includes('Administrator')) return true;

    return false;
  }

  private getRoles(token: string): Array<string> {
    let decodedToken = this.jwtHelper.decodeToken(token);

    return decodedToken.role;
  }

  private tokenExpired(token: string) {
    const expiry = JSON.parse(atob(token.split('.')[1])).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }

  handelError(errorRes) {
    let errorMessage = 'An unknown error occured!';

    if (!errorRes.error || !errorRes.error.result) {
      return throwError(() => {
        return new Error(errorMessage);
      });
    }

    switch (errorRes.error.result) {
      case 'INVALID_CREDENTIALS':
        errorMessage = 'Invalid Login Credentials';
        break;
      case 'EMAIL_EXISTS':
        errorMessage = 'An Account Already Exists With That Email';
        break;

      case 'Invalid client request':
        this.logout();
        this.router.navigateByUrl('/login');
        break;

      default:
        break;
    }

    return throwError(() => {
      return new Error(errorMessage);
    });
  }
}
