import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { combineLatest, map } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/company.service';
import { CoupeService } from 'src/app/services/coupe.service';
import { DeviceService } from 'src/app/services/device.service';
import { TreeService } from 'src/app/services/tree.service';
import {saveAs} from "file-saver";

@Component({
  selector: 'app-tree-export',
  templateUrl: './tree-export.component.html',
  styleUrls: ['./tree-export.component.css']
})
export class TreeExportComponent implements OnInit {

  treeExportForm: UntypedFormGroup;
  isLoading = false;

  @Output() filterChanged = new EventEmitter<String>(null);

  vm$ = combineLatest([
    this.deviceService.getAll(),
    this.companyService.companies,
    this.coupeService.getAll()
  ]).pipe(
    map(([devices, companies, coupes,]) => ({
      devices,
      companies,
      coupes,
    }))
  )

  user: User = null;

  constructor(public deviceService: DeviceService, public companyService: CompanyService, public coupeService: CoupeService, public authService: AuthService, private treeService: TreeService) {

authService.user.subscribe(user => {
  this.user = user;
})
  }

  ngOnInit(): void {

    this.treeExportForm = new UntypedFormGroup({
      'coupes': new UntypedFormControl(null),
      'devices': new UntypedFormControl(null),
      'companies': new UntypedFormControl(),
  });

  this.treeExportForm.get('companies').patchValue([this.user.companyID]);

  }

  exportTreeData()
  {
    let filter = '';

    filter += this.buildDeviceFilter();

    filter += this.buildCompaniesFilter();

    filter += this.buildCoupesFilter();

    filter = filter.endsWith('&') ? filter.slice(0, -1) : filter;

    filter = filter.length > 0 ? `?${filter}` : filter;

    this.isLoading = true;

    this.treeService.getAll(filter).subscribe(result =>
      {
        const date = new Date();

        saveAs(result, `treeExport-${date.toLocaleDateString().replace('/','.')}.csv`);

        this.isLoading = false;
      }
    );

  }

  private buildDeviceFilter()
  {
   let devices = this.treeExportForm.get('devices').value as Array<Number>;

   let queryString = '';

    if(devices == null || devices.length == 0)
    {
      return '';
    }

    devices.forEach(deviceID => {
      queryString = queryString + `deviceID=${deviceID}&`
    })

   return queryString;

  }

  private buildCompaniesFilter()
  {
    let companies = this.treeExportForm.get('companies').value as Array<Number>

    let queryString = '';

    if(companies == null || companies.length == 0)
    {
      return '';
    }

    companies.forEach(companyID => {
      queryString = queryString + `companyID=${companyID}&`
    })

   return queryString;
  }

  private buildCoupesFilter()
  {
    let coupes = this.treeExportForm.get('coupes').value as Array<Number>

    let queryString = '';

    if(coupes == null || coupes.length == 0)
    {
      return '';
    }

    coupes.forEach(coupeID => {
      queryString = queryString + `coupeID=${coupeID}&`
    })

   return queryString;
  }

  resetForm()
  {
    this.treeExportForm.reset();
  }




}
