<ng-container *ngIf="vm$ | async as vm">

<form class="grid grid--gap-sm" [formGroup]="filterForm" (ngSubmit)="buildFilter()">

  <mat-form-field appearance="outline">
    <mat-label>Coupes</mat-label>
    <mat-select formControlName="coupes" multiple>
      <mat-option *ngFor="let coupe of vm.coupes" [value]="coupe.id">{{
        coupe.coupeName
      }}</mat-option>
    </mat-select>
  </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>devices</mat-label>
        <mat-select formControlName="devices" multiple>
          <mat-option *ngFor="let device of vm.devices" [value]="device.id">{{
            device.serial
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="user.checkIsAdmin()" appearance="outline">
        <mat-label>Companies</mat-label>
        <mat-select [disabled]="!user.checkIsAdmin()" formControlName="companies" multiple>
          <mat-option  *ngFor="let company of vm.companies" [value]="company.id">{{
            company.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <button class="button button--brand" [disabled]="!filterForm.dirty" type="submit">Apply filter</button>
      <button (click)="resetForm()" class="button button--danger" >Remove filter</button>
  </form>

</ng-container>
