import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { CompaniesComponent } from './components/main/companies/companies.component';
import { CoupesComponent } from './components/main/coupes/coupes.component';
import { DashboardComponent } from './components/main/dashboard/dashboard.component';
import { DevicesComponent } from './components/main/devices/devices.component';
import { MainComponent } from './components/main/main.component';
import { UsersComponent } from './components/main/users/users.component';
import { MapComponent } from './components/map/map.component';
import { AuthGuard } from './guards/auth.guard';
import { RoleGuard } from './guards/role.guard';

const guards = [AuthGuard,RoleGuard];


const routes: Routes = [
  {
    path: 'login', component:LoginComponent,
  },
  {
    path: 'map', component:MapComponent, canActivate: [AuthGuard]
  },
  {
    path: '', component: MainComponent,canActivate:[AuthGuard], children: [
      {
        path: 'coupes',
        component: CoupesComponent,
        outlet: 'aux'
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        outlet: 'aux'
      },
      {
        path: 'devices',
        component: DevicesComponent,
        outlet: 'aux',
        canActivate:guards
      },
      {
        path: 'companies',
        component: CompaniesComponent,
        outlet: 'aux',
        canActivate:guards
      },
      {
        path: 'users',
        component: UsersComponent,
        outlet: 'aux',
        canActivate:guards
      }
    ],
  },

  {
    path: "**", redirectTo: 'map'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
