import { AfterViewInit, Component, ElementRef, HostBinding, OnInit, ViewChild } from '@angular/core';
import OlMap from 'ol/Map';
import { ProjectionLike } from 'ol/proj';
import View from 'ol/View';
import * as olProj from 'ol/proj';
import { mapProjEPSG } from 'src/app/_helpers/projection_setup';
import { base_map_defs } from 'src/app/_helpers/basemaps';
import { populateLayerGroup } from 'src/app/_helpers/LayerBuilder';
import LayerGroup from 'ol/layer/Group';

@Component({
  selector: 'app-spatial-reference-selector',
  templateUrl: './spatial-reference-selector.component.html',
  styleUrls: ['./spatial-reference-selector.component.css']
})
export class SpatialReferenceSelectorComponent implements OnInit,AfterViewInit {

  @ViewChild('mapElementRef1', { static: true }) mapElementRef1: ElementRef;
  @ViewChild('mapElementRef2', { static: true }) mapElementRef2: ElementRef;
  @ViewChild('mapElementRef3', { static: true }) mapElementRef3: ElementRef;
  @ViewChild('mapElementRef4', { static: true }) mapElementRef4: ElementRef;
  @ViewChild('mapElementRef5', { static: true }) mapElementRef5: ElementRef;
  @ViewChild('mapElementRef6', { static: true }) mapElementRef6: ElementRef;

  proj : ProjectionLike | null = olProj.get(mapProjEPSG);
  baseMapGroup = new LayerGroup({
    layers: [],
    title: 'Background Maps',
    fold: 'open',
  } as any);

  constructor() { }

 ngOnInit(): void {

   populateLayerGroup(base_map_defs, this.baseMapGroup, ()=>{});

  }

  ngAfterViewInit(): void {
      let map = new OlMap({
        layers: [
          this.baseMapGroup
        ],
        view: new View({
          center: [494095, 5343594],
          projection: this.proj == null ? null : this.proj,
          zoom: 5,
          maxZoom: 23,
        }),
        pixelRatio: window.devicePixelRatio,
        maxTilesLoading: 256,
        controls: [
        ],

      });
map.setTarget(this.mapElementRef1.nativeElement);

map.updateSize();
  }



}
