import { Injectable } from '@angular/core';


const TOKEN_KEY = 'auth-token';
const REFRESH_KEY = 'auth-refresh';
const USER_KEY = 'auth-user';



@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {


  constructor() { }

  public saveToken(token: string): void {

    localStorage.removeItem(TOKEN_KEY);
    localStorage.setItem(TOKEN_KEY,token );

  }

  public getToken(): any {

     return localStorage.getItem(TOKEN_KEY)
  }

  public removeToken(): void{
    localStorage.removeItem(TOKEN_KEY)
  }

  public saveUser(user): void
  {
    localStorage.removeItem(USER_KEY)
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): any {
   return localStorage.getItem(USER_KEY)
  }

  public removeUser(): void{
    localStorage.removeItem(USER_KEY)
  }
}


