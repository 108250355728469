<header >
  <h1>Manage Devices</h1>
  <section class="grid grid--gap grid--column">
  <button (click)="addDevice()" class="button button--brand">Add Device</button>
  <a type="button" style="text-decoration: none;" [routerLink]="[ '',{ outlets: {primary:'map',aux:null} }]" class="button button--blue">Launch Map</a>
  </section>
</header>
<main class="card">

  <div class="user-management_table-container">
    <div class="table-responsive">
      <table mat-table [dataSource]="dataSource" class="user-management_table">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="serial">
          <th mat-header-cell *matHeaderCellDef> Serial </th>
          <td mat-cell *matCellDef="let element"> {{element.serial}} </td>
        </ng-container>

        <ng-container matColumnDef="number">
          <th mat-header-cell *matHeaderCellDef> Number </th>
          <td mat-cell *matCellDef="let element"> {{element.number}} </td>
        </ng-container>
        <!-- Weight Column -->
        <ng-container matColumnDef="company">
          <th mat-header-cell *matHeaderCellDef> Company </th>
          <td mat-cell *matCellDef="let element"> {{element.company}} </td>
        </ng-container>

        <ng-container matColumnDef="user">
          <th mat-header-cell *matHeaderCellDef> User </th>
          <td mat-cell *matCellDef="let element"> {{element.user}} </td>
        </ng-container>
        <ng-container matColumnDef="color">
          <th mat-header-cell *matHeaderCellDef> Display Color </th>
          <td mat-cell *matCellDef="let element" > <div style="width: 100%; height:50px" [style.background-color]="element.color"></div></td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell  *matHeaderCellDef > Actions </mat-header-cell>
          <mat-cell class="grid-column" *matCellDef="let row" >
               <button class="button button--blue" (click)="edit(row)" >Edit</button>
               <button class="button button--danger" (click)="delete(row)" >Delete</button>
          </mat-cell>
        </ng-container>



        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
    aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>


</main>
