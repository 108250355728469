
<header>
  <h1 class="grid grid--column"><img width="50px" height="50px" src="/assets/images/geoTree.logo.png">GeoTree</h1>
</header>

<form [formGroup]="loginForm"  (ngSubmit)="onSubmit()" class="login_form">
  <mat-form-field appearance="outline">
    <mat-label>Email</mat-label>
    <input formControlName="email" matInput placeholder="example@example.com.au">
    <mat-error *ngIf="email.invalid && (email.dirty || email.touched)">
      <div *ngIf="email.errors?.['required']">
        Email is required.
      </div>
      <div *ngIf="email.errors?.['email']">
        Please enter a valid email address.
      </div>
    </mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Password</mat-label>
    <input matInput formControlName="password" type="password">
    <mat-error *ngIf="password.invalid && (password.dirty || password.touched)">
      <div *ngIf="email.errors?.['required']">
        Password is required.
      </div>
    </mat-error>
  </mat-form-field>

<mat-error *ngIf="error">{{error}}</mat-error>

<button type="submit" [disabled]="!loginForm.valid" class="button button--brand">Login</button>

<a class="center" [routerLink]="['/account/forgotPassword']">Forgot your password</a>
</form>
