<h2 mat-dialog-title>Add Device</h2>
<mat-dialog-content class="mat-typography">

  <form
    [formGroup]="editDeviceForm"
    (ngSubmit)="onSubmit()"
  >
  <section class="grid">
    <mat-form-field appearance="outline">
      <mat-label>Device serial</mat-label>
      <input formControlName="serial" matInput placeholder="5B4C328F1D">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Device number</mat-label>
      <input type="number" formControlName="number" matInput placeholder="1">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Company</mat-label>
      <mat-select formControlName="company">
        <option [value]="null" disabled>Choose company</option>
        <mat-option *ngFor="let company of companies" [value]="company.name">
          {{company.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>User</mat-label>
      <mat-select formControlName="user">
        <option [value]="null" disabled>Choose user</option>
        <mat-option *ngFor="let user of users" [value]="user.email">
          {{user.firstName}} {{user.surname}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Map Display Color</mat-label>
      <mat-select formControlName="color">
        <mat-select-trigger>
            <div style="width: 100% ; height:25px;" [style.background-color]="editDeviceForm.get('color')?.value"></div>
        </mat-select-trigger>
        <option [value]="null" disabled>Choose Color</option>
        <mat-option *ngFor="let color of colors" [style.background-color]="color.colorCode"  [value]="color.colorCode">

        </mat-option>
      </mat-select>
    </mat-form-field>
  </section>

  </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <footer>
    <button type="button" class="button button--black-outline" [mat-dialog-close]="false">
      Cancel
    </button>
    <button
      type="button"
      class="button button--blue"
      [disabled]="!editDeviceForm.dirty"
      (click)="onSubmit()"
      type="submit"
    >
      Update
    </button>
  </footer>
</mat-dialog-actions>
