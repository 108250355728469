import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Feature, PluggableMap } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { ErrorComponent } from 'src/app/components/shared/snackbar/error/error.component';
import { SuccessComponent } from 'src/app/components/shared/snackbar/success/success.component';
import { AuthService } from 'src/app/services/auth.service';
import { CompanyService } from 'src/app/services/company.service';
import { ConvertShapeFilesToFeatureCollection } from 'src/app/_helpers/shapeParser';

@Component({
  selector: 'app-importshape',
  templateUrl: './importshape.component.html',
  styleUrls: ['./importshape.component.css']
})
export class ImportshapeComponent implements OnInit {

  @Input() map: PluggableMap;
  @Output() features = new EventEmitter<Array<Feature<any>>>();

  constructor(private snackBar: MatSnackBar) { }

  async ngOnInit(): Promise<void> {

    this.map.getViewport().addEventListener('dragover', function(event) {
      event.preventDefault();
    });

    this.map.getViewport().addEventListener('drop', async (event) => {

      event.preventDefault();

      try{
        let features = await ConvertShapeFilesToFeatureCollection(event.dataTransfer.files);

        this.features.emit(features);

        this.snackBar.openFromComponent(SuccessComponent, {
          duration: 20 * 1000,
          data: 'Successfully imported shape file!',
          panelClass: ['success-snackbar']
        });
      }
      catch(message)
      {
        this.snackBar.openFromComponent(ErrorComponent, {
          duration: 20 * 1000,
          data: message,
          panelClass: ['error-snackbar']
        });
      }


    });
  }

}
