<h2 mat-dialog-title>Restore Tree Point</h2>
<mat-dialog-content class="mat-typography">
<p>are you sure you want to restore this point?</p>

<p><span class="danger">This action cannot be undone</span></p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <footer>
    <button type="button" class="button button--black-outline" [mat-dialog-close]="false">
      No
    </button>
    <button class="button button--brand" [mat-dialog-close]="true">
      Yes
    </button>
  </footer>
</mat-dialog-actions>
