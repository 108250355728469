import { Feature } from "ol";
import { Point } from "ol/geom";
import { Options } from "ol/layer/Base";
import VectorLayer from "ol/layer/Vector";
import WebGLPointsLayer from "ol/layer/WebGLPoints";
import VectorSource, {Options as VectorOptions} from "ol/source/Vector";
import { Style } from "ol/style";
import { LiteralStyle } from "ol/style/literal";

type extendedOptions = Options & VectorOptions & { title: string, source: VectorSource<any>, style?:Style };

export class VectorLayerExtended extends VectorLayer<VectorSource<any>> {
  constructor(options: extendedOptions) {
    super(options);

    this.set("title", options.title);
  }

}


type extendedWebglOptions = Options & VectorOptions & { title: string, source: VectorSource<any>, style:LiteralStyle };

export class WebGLPointsLayerExtended extends WebGLPointsLayer<VectorSource<any>> {
  constructor(options: extendedWebglOptions) {
    super(options);

    this.set("title", options.title);
  }

}
