<header >
  <h1>Manage Users</h1>
  <section class="grid grid--gap grid--column">
    <button (click)="addUser()" class="button button--brand">Add User</button>
    <a type="button" style="text-decoration: none;" [routerLink]="[ '',{ outlets: {primary:'map',aux:null} }]" class="button button--blue">Launch Map</a>
  </section>

</header>
<main class="card">

  <div class="user-management_table-container">
    <div class="table-responsive">
      <table mat-table [dataSource]="dataSource" class="user-management_table">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition"
            'userId', 'firstName', 'surname','email','isEmailConfirmed','isEnabled','company','lastLoginDate','roles']



            -->

        <!-- Position Column -->
        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef> First Name </th>
          <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
        </ng-container>

        <ng-container matColumnDef="surname">
          <th mat-header-cell *matHeaderCellDef>Surname </th>
          <td mat-cell *matCellDef="let element"> {{element.surname}} </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef> Email </th>
          <td mat-cell *matCellDef="let element"> {{element.email}} </td>
        </ng-container>

        <ng-container matColumnDef="isEmailConfirmed">
          <th mat-header-cell *matHeaderCellDef> Email Confirmed </th>
          <td mat-cell *matCellDef="let element"> {{element.isEmailConfirmed}} </td>
        </ng-container>

        <ng-container matColumnDef="isEnabled">
          <th mat-header-cell *matHeaderCellDef> Active </th>
          <td mat-cell *matCellDef="let element"> {{element.isEnabled}} </td>
        </ng-container>

        <ng-container matColumnDef="lastLoginDate">
          <th mat-header-cell *matHeaderCellDef> Last Login</th>
          <td mat-cell *matCellDef="let element"> {{element.lastLoginDate}} </td>
        </ng-container>

        <ng-container matColumnDef="roles">
          <th mat-header-cell *matHeaderCellDef> Roles </th>
          <td mat-cell *matCellDef="let element"> {{element.roles}}</td>
        </ng-container>

        <ng-container matColumnDef="company">
          <th mat-header-cell *matHeaderCellDef> Company </th>
          <td mat-cell *matCellDef="let element"> {{element.company}} </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell  *matHeaderCellDef > Actions </mat-header-cell>
          <mat-cell class="grid-column" *matCellDef="let row" >
               <button class="button button--blue" (click)="edit(row)" >Edit</button>
               <button class="button button--blue" (click)="resetPassword(row)" >Reset Password</button>
               <button class="button button--danger" (click)="delete(row)" >Delete</button>
          </mat-cell>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
    aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>


</main>
