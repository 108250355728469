import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Company, CompanyService } from 'src/app/services/company.service';
import { CustomValidators } from 'src/app/_helpers/customValidators';

@Component({
  selector: 'app-add-user-dialog',
  templateUrl: './add-user-dialog.component.html',
  styleUrls: ['./add-user-dialog.component.css']
})
export class AddUserDialogComponent implements OnInit {

  addUserForm: UntypedFormGroup;

  private companySub$: Subscription;

  public companies: Array<Company>;

  constructor(private companyService:CompanyService,public dialogRef: MatDialogRef<AddUserDialogComponent>) {

    this.companySub$ = companyService.companies.subscribe(companies => {
      this.companies = companies
    });

    this.addUserForm = new UntypedFormGroup({
      'firstName': new UntypedFormControl(null, CustomValidators.required),
      'surname': new UntypedFormControl(null, CustomValidators.required),
      'email': new UntypedFormControl(null, [CustomValidators.required, CustomValidators.email]),
      'passwordData' : new UntypedFormGroup({
        'password': new UntypedFormControl(null, [CustomValidators.passwordHasUpper, CustomValidators.minLength(8), CustomValidators.passwordHasSpecial, CustomValidators.required]),
        'confirmPassword': new UntypedFormControl(null,CustomValidators.required)
      },{validators: [CustomValidators.passwordsMatch]}),
      'company': new UntypedFormControl(null, CustomValidators.required)});

   }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
      this.companySub$?.unsubscribe();
  }

  onSubmit(){

    let formValue = this.addUserForm.value;
    let userSubmission : {firstName: string, surname:string,email:string,password:string,company:string} = {

      firstName: formValue.firstName,
      surname:formValue.surname,
      email:formValue.email,
      password:formValue.passwordData.password,
      company:formValue.company
    }

    this.dialogRef.close(userSubmission);
  }
}
