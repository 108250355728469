<form (ondragover)="dragover($event)" (drop)="onDrop($event)" class="upload-form center--inline center--text gap" >
  <span><i class="fa fa-cloud-upload-alt fa-3x grid center"></i></span>
  <span>Drag files to upload</span>
  <span>or</span>
  <input #fileInput style="display:none" (change)="fileSelected($event.target.files)" type="file" id="myFile" name="filename">
  <button class="button button--blue-outline" (click)="fileInput.click()">Browse Files</button>

  <mat-form-field *ngIf="user.checkIsAdmin()" appearance="outline">
    <mat-label>Assign to Company</mat-label>
    <mat-select [(value)]="selectedCompany" >
      <option [value]="null" disabled>Choose company</option>
      <mat-option *ngFor="let company of filteredCompanySub | async as companies" [value]="company">
        {{company.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
    <button class="button button--brand" [disabled]="file == null || uploading" (click)="uploadFile()" type="submit"><span *ngIf="!uploading">Import</span><i *ngIf="uploading" class="fa fa-circle-notch fa-spin"></i></button>
</form>

<section *ngIf="data != null">
  <header><h1>Summary</h1></header>
  <main class="grid">
    <span>Total trees planted: {{data.totalImported}}</span>
    <span>Total rows: {{data.totalRows}}</span>

    <div><h2>Devices</h2>
      <div class="grid" *ngFor="let device of data.devices">
        <span>Device serial: {{device.serial}}</span>
        <span>Device company: {{device.company}}</span>
        <span>Device user: {{device.user}}</span>
      </div>
    </div>

  </main>
</section>
